//import "./wdyr";
import React from "react";
import ReactDOM from "react-dom/client";
//import './index.css';
import App from "./App";
import "styles/variables.css";
import "styles/global.css";
import "styles/toastify.css";

import { enableWhyDidYouRender } from "./why-did-you-render";

import reportWebVitals from "./reportWebVitals";
//enableWhyDidYouRender(React);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
