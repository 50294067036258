import React from "react";
import appLogo from "assets/networkgpt.png";

export default function CardImage(props) {
  return (
    <img
      {...props}
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = appLogo;
      }}
    />
  );
}
