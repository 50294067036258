import React from "react";
import Button from "components/common/Button";
import utils from "utils/utils";
import CompanyCard from "./CompanyCard";
import JobCard from "./JobCard";
import ProfileCard from "./ProfileCard";

// Common bottom area for all cards, used for selection
function BottomArea({ handleCardSelect, selectedCardIds, actionType, item }) {
  return (
    <React.Fragment>
      {actionType === "selector-single" && (
        <Button
          active={selectedCardIds.includes(item.id)}
          onClick={() => handleCardSelect(item.id, item)}
          text="Select"
        />
      )}
    </React.Fragment>
  );
}

function getInsideCardComponent({ responseType, item, sendMessageFun }) {
  switch (responseType) {
    case "profiles":
      return (
        <ProfileCard
          profile={item}
          sendMessageFun={sendMessageFun}
          key={item.id} // TODO make it just an id on backend
        />
      );
    case "jobs":
      return (
        <JobCard
          job={item}
          key={item.id} // TODO make it just an id on backend
        />
      );
    case "companies":
      return <CompanyCard company={item} key={item.id} />;
    default:
      utils.notify("Unknown response type");
  }
}

// If legacy is true, we use the old card component
function getCardComponent({
  item,
  responseType,
  handleCardSelect,
  selectedCardIds,
  actionType,
  legacy,
  sendMessageFun,
}) {
  const selectableClass =
    actionType === "selector-single" ? "selectable-card" : "";
  const selected = selectedCardIds.includes(item.id);
  const selectedClass = selected ? "selected" : "";
  const card = getInsideCardComponent({ responseType, item, sendMessageFun });
  return (
    <div
      className={`response-card ${selectableClass} ${selectedClass}`}
      onClick={() => handleCardSelect(item.id, item)}
    >
      {card}
    </div>
  );

  /*const bottom = (
    <BottomArea
      handleCardSelect={handleCardSelect}
      selectedCardIds={selectedCardIds}
      actionType={actionType}
      item={item}
      selected={selected}
    />
  );*/
}

export const humanizedNum = (x) => {
  if (x == null) return null;

  if (x < 1000) return x.toString(); // Add this line

  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getIndustryMessage = (company) => {
  let industry = "";
  if (!company.industryMain) return "";
  let words = company.industryMain.replaceAll("-", " ");

  // capitaize the first letter of each word
  words = words.split(" ");
  words = words
    .map((word) => {
      // do not capitalize and
      if (word === "and") return word;

      return word[0].toUpperCase() + word.substring(1);
    })
    .join(" ");

  industry = words; // + " Business";

  // append year founded if available
  if (company.yearFounded) {
    industry = industry + " since " + company.yearFounded;
  }
  return industry;
};

export { getCardComponent };
