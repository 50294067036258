import { SignUp } from "@clerk/clerk-react";
import useClearLocalStorage from "hooks/useClearLocalStorage";
import styles from "styles/Pages.module.css";

export default function SingInPage() {
  useClearLocalStorage();
  return (
    <div className={styles.authContainer}>
      <SignUp />
    </div>
  );
}
