import React, { useEffect, useRef, useState } from "react";
import "styles/terminal.css";

const ColorMode = {
  Light: "light",
  Dark: "dark",
};

const TerminalInput = ({ children }: { children?: React.ReactChild }) => {
  return (
    <div className="react-terminal-line react-terminal-input">{children}</div>
  );
};

const TerminalOutput = ({ children }: { children?: React.ReactChild }) => {
  return <div className="react-terminal-line">{children}</div>;
};

const Terminal = React.forwardRef(
  (
    {
      name,
      prompt,
      height = "100%",
      colorMode,
      onInput,
      children,
      startingInputValue = "",
      redBtnCallback,
      yellowBtnCallback,
      greenBtnCallback,
      value,
      onChange,
      onKeyPress,
      id,
    },
    ref
  ) => {
    //const [currentLineInput, setCurrentLineInput] = useState("");
    const [cursorPos, setCursorPos] = useState(0);

    const currentLineInput = value;
    const scrollIntoViewRef = useRef(null);

    const updateCurrentLineInput = onChange;

    // Calculates the total width in pixels of the characters to the right of the cursor.
    // Create a temporary span element to measure the width of the characters.
    const calculateInputWidth = (inputElement, chars) => {
      const span = document.createElement("span");
      span.style.visibility = "hidden";
      span.style.position = "absolute";
      span.style.fontSize = window.getComputedStyle(inputElement).fontSize;
      span.style.fontFamily = window.getComputedStyle(inputElement).fontFamily;
      span.innerText = chars;
      document.body.appendChild(span);
      const width = span.getBoundingClientRect().width;
      document.body.removeChild(span);
      // Return the negative width, since the cursor position is to the left of the input suffix
      return -width;
    };

    const clamp = (value, min, max) => {
      if (value > max) return max;
      if (value < min) return min;
      return value;
    };

    const handleInputKeyDown = (event) => {
      if (event.key === "Enter") {
        onKeyPress && onKeyPress(event);

        setCursorPos(0);
      } else if (
        ["ArrowLeft", "ArrowRight", "ArrowDown", "ArrowUp", "Delete"].includes(
          event.key
        )
      ) {
        const inputElement = event.currentTarget;
        let charsToRightOfCursor = "";
        let cursorIndex =
          currentLineInput.length - (inputElement.selectionStart || 0);
        cursorIndex = clamp(cursorIndex, 0, currentLineInput.length);

        if (event.key === "ArrowLeft") {
          if (cursorIndex > currentLineInput.length - 1) cursorIndex--;
          charsToRightOfCursor = currentLineInput.slice(
            currentLineInput.length - 1 - cursorIndex
          );
        } else if (event.key === "ArrowRight" || event.key === "Delete") {
          charsToRightOfCursor = currentLineInput.slice(
            currentLineInput.length - cursorIndex + 1
          );
        } else if (event.key === "ArrowUp") {
          charsToRightOfCursor = currentLineInput.slice(0);
        }

        const inputWidth = calculateInputWidth(
          inputElement,
          charsToRightOfCursor
        );
        setCursorPos(inputWidth);
      } else {
        onKeyPress && onKeyPress(event);
      }
    };

    // An effect that handles scrolling into view the last line of terminal input or output
    const performScrolldown = useRef(false);
    useEffect(() => {
      if (performScrolldown.current) {
        // skip scrolldown when the component first loads
        setTimeout(
          () =>
            scrollIntoViewRef?.current?.scrollIntoView({
              behavior: "auto",
              block: "nearest",
            }),
          500
        );
      }
      performScrolldown.current = true;
    }, [children]);

    // We use a hidden input to capture terminal input; make sure the hidden input is focused when clicking anywhere on the terminal
    useEffect(() => {
      if (onChange == null) {
        return;
      }
      // keep reference to listeners so we can perform cleanup
      const elListeners: {
        terminalEl: Element,
        listener: EventListenerOrEventListenerObject,
      }[] = [];
      for (const terminalEl of document.getElementsByClassName(
        "react-terminal-wrapper"
      )) {
        const listener = () =>
          terminalEl?.querySelector(".terminal-hidden-input")?.focus();
        terminalEl?.addEventListener("click", listener);
        elListeners.push({ terminalEl, listener });
      }
      return function cleanup() {
        elListeners.forEach((elListener) => {
          elListener.terminalEl.removeEventListener(
            "click",
            elListener.listener
          );
        });
      };
    }, [onChange]);

    const classes = ["react-terminal-wrapper"];
    if (colorMode === ColorMode.Light) {
      classes.push("react-terminal-light");
    }

    //classes.push("react-terminal-wrapper")

    return (
      <div className="user-terminal-input">
        <div id={id} className={classes.join(" ")} data-terminal-name={name}>
          <div className="react-terminal-window-buttons">
            <button
              className={`${yellowBtnCallback ? "clickable" : ""} red-btn`}
              disabled={!redBtnCallback}
              onClick={redBtnCallback}
            />
            <button
              className={`${yellowBtnCallback ? "clickable" : ""} yellow-btn`}
              disabled={!yellowBtnCallback}
              onClick={yellowBtnCallback}
            />
            <button
              className={`${greenBtnCallback ? "clickable" : ""} green-btn`}
              disabled={!greenBtnCallback}
              onClick={greenBtnCallback}
            />
          </div>
          <div className="react-terminal" style={{ height }}>
            {children}
            {onChange && (
              <div
                className="react-terminal-line react-terminal-input react-terminal-active-input"
                data-terminal-prompt={prompt || "$"}
                key="terminal-line-prompt"
              >
                {currentLineInput}
                <span
                  className="cursor"
                  style={{ left: `${cursorPos + 1}px` }}
                ></span>
              </div>
            )}
            <div ref={scrollIntoViewRef}></div>
          </div>
          <input
            ref={ref}
            className="terminal-hidden-input"
            placeholder="Terminal Hidden Input"
            value={currentLineInput}
            rows="1"
            autoFocus={onChange != null}
            onChange={updateCurrentLineInput}
            onKeyDown={handleInputKeyDown}
          />
        </div>
      </div>
    );
  }
);

export { TerminalInput, TerminalOutput };
export default Terminal;
