import React from "react";
import appLogo from "assets/networkgpt.png";
import CardSection from "./CardSection";
import SocialIcons from "./SocialIcons";
import Button from "components/common/Button";
import CardImage from "components/cards/CardImage";
import styles from "styles/ProfileCard.module.css";
import commonStyles from "styles/Card.module.css";

import utils from "utils/utils";
import { ExpandableText } from "components/common/ExpandableText";
import CardHeader from "components/cards/CardHeader";

export default function Card({ profile, sendMessageFun }) {
  // if action type is not select, then we don't need to show some buttons
  // TODO use constants for action types
  if (!profile.experience && profile.experiences)
    profile.experience = profile.experiences;
  const experiences = profile.experience.slice(0, 3);
  const WorkEmailMessage = `Find the work email for ${profile.linkedinUrl}`;
  const personalEmailMessage = `Find the personal email for ${profile.linkedinUrl}`;

  const allskills = utils.sortProfileSkills(profile.analysis?.skills);
  const skills = allskills
    .slice(0, 6)
    .map((skill) =>
      skill.years ? `${skill.skill} (${skill.years})` : `${skill.skill$}`
    );

  const location = profile.analysis?.location;
  const locationText = [location?.city, location?.region, location?.country]
    .filter((x) => x)
    .join(", ");

  return (
    <div className={styles.container}>
      <div>
        <CardHeader image={profile.profilePic}>
          <div className={styles.fullName}>
            <h1>{profile.fullName}</h1>
            <SocialIcons socialLinks={profile} />
          </div>
          <p> {profile.title /* Only visible for test cards now */}</p>
          <p>{locationText}</p>
        </CardHeader>

        <ExpandableText
          className={styles.shortSummary}
          text={profile.analysis?.summaryShort}
          foldedLength={100}
        />

        <CardSection title="Companies">
          {experiences.map((experience) => (
            <div className={styles.company}>
              <CardImage src={experience.logo || appLogo} alt="Company Logo" />
              <div className={styles.companyDetails}>
                <h4>
                  {experience.company}
                  {/*", "*/}
                  {/*utils.humanizedWorkYears(experience.start, experience.end)*/}
                </h4>
                <p>{experience.title}</p>
              </div>
            </div>
          ))}
        </CardSection>

        {profile.education.length > 0 && (
          <CardSection title="Education">
            {profile.education?.slice(0, 2)?.map((education, index) => (
              <div className={styles.educationDetails} key={index}>
                {/*<img src={education.logo || appLogo} alt="Company Logo" />*/}
                <h4>{education.from}</h4>
                <p>
                  {education.title}, {education.start} - {education.end}
                </p>
                <p></p>
              </div>
            ))}
          </CardSection>
        )}
      </div>

      <div className={styles.bottom}>
        <div style={{ flex: 1 }}>
          <div className={styles.skills}>
            {skills.map((skill, index) => (
              <span className={styles.skill}>
                {skill}{" "}
                {index !== skills.length - 1 && (
                  <span className={styles.bullet}> • </span>
                )}
              </span>
            ))}
          </div>

          <div className={styles.profileContact}>
            <Button onClick={() => sendMessageFun(WorkEmailMessage)}>
              Work Email
            </Button>
            <Button onClick={() => sendMessageFun(personalEmailMessage)}>
              Personal Email
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
