import { IconContext } from "react-icons";
import {
  FaAngellist,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { TbBrandCrunchbase } from "react-icons/tb";
import styles from "styles/SocialIcons.module.css";

const icons = {
  linkedinUrl: <FaLinkedin />,
  /*facebook: <FaFacebook />,
  crunchbase: <TbBrandCrunchbase />,
  youtube: <FaYoutube />,
  instagram: <FaInstagram />,
  twitter: <FaTwitter />,
  angellist: <FaAngellist />,*/
};

// socialLinks object that include links to socials
// example {facebook: 'https://facebook.com/company', twitter: ...}
export default function SocialIcons({ socialLinks }) {
  if (Object.keys(socialLinks).length === 0) return null;
  return (
    <div className={styles.container} style={{ display: "inline-block" }}>
      {Object.keys(icons).map(
        (social, index) =>
          socialLinks[social] && (
            <a
              href={socialLinks[social]}
              key={index}
              target="_blank"
              rel="noreferrer"
            >
              <IconContext.Provider value={{ style: { fontSize: 17 } }}>
                {icons[social]}
              </IconContext.Provider>
            </a>
          )
      )}
    </div>
  );
}
