import { useQuery } from "@tanstack/react-query";
import Spinner from "components/common/Spinner";
import api from "api/api";
import { ConfigSection, ConfigTitle } from "components/sidebar/ConfigSection";
import utils from "utils/utils";
import ErrorPage from "pages/ErrorPage";
import ConfigPage from "components/sidebar/ConfigPage";
import ExtraCreditInfo from "components/sidebar/ExtraCreditInfo";

export default function CreditPage() {
  const { data: creditTransactions, error: errTransactions } = useQuery({
    queryKey: ["creditTransactions"],
    queryFn: api.fetchCreditTransactions,
    cacheTime: 0,
  });

  const { data: creditBalance, error: errBalance } = useQuery({
    queryKey: ["creditBalance"],
    queryFn: api.fetchCreditBalance,
    cacheTime: 0,
  });

  if (errBalance || errTransactions)
    return (
      <ConfigPage>
        <ErrorPage />
      </ConfigPage>
    );

  if (!creditBalance || !creditTransactions)
    return (
      <ConfigPage>
        <Spinner />
      </ConfigPage>
    );

  return (
    <ConfigPage>
      <ConfigTitle title="Credit" subtitle="Your credit and transactions" />
      <ConfigSection title="Current Balance">
        <p>
          <h1> {creditBalance} Credits </h1>{" "}
        </p>
        <ExtraCreditInfo />
      </ConfigSection>

      <ConfigSection title="Transactions">
        <table>
          <thead>
            <tr>
              <th scope="col" style={{ width: "30%" }}>
                Date
              </th>
              <th scope="col" style={{ width: "20%" }}>
                Amount
              </th>
              <th scope="col" style={{ width: "55%" }}>
                Details
              </th>
            </tr>
          </thead>
          <tbody>
            {creditTransactions.map((transaction) => (
              <tr key={transaction.journalId}>
                <td scope="row" data-label="Date">
                  {utils.toReadableDate(transaction.timestamp)}
                </td>
                <td data-label="Ammount">{transaction.amount}</td>
                <td data-label="Details">{transaction.details}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </ConfigSection>
    </ConfigPage>
  );
}
