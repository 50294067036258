import { useRef, useEffect } from "react";
import utils from "utils/utils";

export default function useAutoScroll(conversationId, messages) {
  const upperRef = useRef(null);
  const bottomRef = useRef(null);

  const scrollTo = (direction) => {
    let extraParams = {};
    let elementRef;
    if (direction === "up") {
      elementRef = upperRef;
    } else {
      elementRef = bottomRef;
      extraParams["behavior"] = utils.screenSmallerThan(768)
        ? "instant"
        : "smooth";
    }

    elementRef.current?.scrollIntoView({
      block: "nearest",
      inline: "start",
      ...extraParams,
      alignToTop: false,
    });
  };

  useEffect(() => {
    if (!utils.screenSmallerThan(768)) {
      setTimeout(() => scrollTo("up"), 20);
    }
    if (typeof process == "undefined" || process.env.NODE_ENV === "production")
      setTimeout(() => {
        //inputRef?.current?.focus();
        scrollTo("bottom");
      }, 1000);
  }, [conversationId]);

  useEffect(() => {
    scrollTo("bottom");
  }, [messages]);

  return [upperRef, bottomRef];
}
