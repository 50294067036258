import styles from "styles/RadioField.module.css";
export default function RadioField({ name, value, onChange, checked }) {
  return (
    <label className={styles.container}>
      <input
        type="radio"
        name={name}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      {value}
      <span className={styles.checkmark}> </span>
    </label>
  );
}
