import { UserButton } from "@clerk/clerk-react";
import Button from "components/common/Button";
import CreditPage from "components/sidebar/CreditPage";
import ExtraCreditInfo from "components/sidebar/ExtraCreditInfo";
import { GiCreditsCurrency } from "react-icons/gi";
import { Link, useLocation } from "react-router-dom";
import { useUserStore } from "store/store";
import styles from "styles/CreditTooltip.module.css";
export default function CreditTooltip() {
  const location = useLocation();
  const setIsProfileModalOpen = useUserStore(
    (state) => state.setProfileModalOpen
  );
  return (
    <div className={styles.tooltiptext}>
      {/*<Link
        className="frontpage-job"
        to="/modal/1"
        state={{ previousLocation: location }}
      >
        Open Modal
  </Link>*/}
      <div style={{ marginTop: "10px" }}>
        <ExtraCreditInfo />
        <div className={styles.buttonContainer}>
          <Button onClick={() => setIsProfileModalOpen(true)}>
            Credit Details
          </Button>
        </div>
      </div>
    </div>
  );
}
