// Conditionally renders one of the following components:
// CompanyCard, JobCard, ProfileCard
import ProfileCard from "./ProfileCard";
import JobCard from "./JobCard";
import CompanyCard from "./CompanyCard";
import styles from "styles/Card.module.css";
import AskMoreCard from "components/cards/AskMoreCard";

export default function Card({
  item,
  responseType,
  sendMessageFun,
  useAsAskMore,
  prompt,
}) {
  return (
    <div className={styles.card}>
      {useAsAskMore && <AskMoreCard prompt={prompt} />}
      {responseType === "profiles" && (
        <ProfileCard profile={item} sendMessageFun={sendMessageFun} />
      )}
      {responseType === "jobs" && <JobCard job={item} />}
      {responseType === "companies" && <CompanyCard company={item} />}
    </div>
  );
}
