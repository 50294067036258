import React, { useState, useEffect } from "react";
import utils from "../../utils/utils";
import { Waveform } from "@uiball/loaders";
import styles from "styles/ThinkingIndicator.module.css";

const steps = [
  {
    text: "Understanding Request",
    duration: 10,
  },
  {
    text: "Analyzing Request",
    duration: 10,
  },
  {
    text: "Searching",
    duration: 10,
  },
  {
    text: "Finding",
    duration: 15,
  },
  {
    text: "Enriching",
    duration: 15,
  },
  {
    text: "Grading",
    duration: 15,
  },
  {
    text: "Search is taking longer than usual. We're still working on it.",
    duration: 70,
  },
  {
    text: "Search is taking longer than usual. We're still working on it.",
    duration: 1,
  },
];

const ThinkingIndicator = () => {
  const [stepMessage, updateStepMessage] = useState(steps[0].text);
  const [fadeIn, setFadeIn] = useState(false);
  useEffect(() => {
    const cycleSteps = async () => {
      for (let i = 1; i < steps.length; i++) {
        setFadeIn(true);
        await utils.sleep(steps[i - 1].duration * 1000);
        setFadeIn(false);
        await utils.sleep(500);
        updateStepMessage(steps[i].text);
      }
    };
    cycleSteps().catch((err) => console.log(err));
  }, []);

  return (
    <div className={styles.container}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Waveform color="white" size={19} />
        <span
          className={`${styles.text}
            ${fadeIn ? styles.fadeIn : styles.fadeOut}`}
          style={{ marginLeft: 5 }}
        >
          {stepMessage}
        </span>
      </div>
    </div>
  );
};

export default ThinkingIndicator;
