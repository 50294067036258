import ProgressBar from "components/sidebar/ProgressBar";

export default function ExtraCreditInfo() {
  return (
    <div>
      <p> Professional Plan: Monthly</p>
      <p> Credits Remaining: 450/500 </p>
      <progress value={75} max={100}>
        75%
      </progress>
      <p>Limit resets in 15 days</p>
    </div>
  );
}
