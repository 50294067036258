// This component will be needed if we want to use separate page

import { UserProfile } from "@clerk/clerk-react";
import CreditPage from "components/sidebar/CreditPage";
import { useEffect } from "react";
import { GiCreditsCurrency } from "react-icons/gi";
import { useLocation, useNavigate } from "react-router-dom";

// instead of clerk modal
export default function UserProfilePage({ path, routing }) {
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location", location);
  useEffect(() => {
    if (location.pathname.endsWith("custom")) return;
    navigate(location.pathname + "#/custom");
  }, [location?.pathname, navigate]);

  return (
    <UserProfile path={path} routing={routing}>
      <UserProfile.Page
        label="Credits"
        labelIcon={<GiCreditsCurrency size={19} />}
        url="custom"
      >
        <CreditPage />
      </UserProfile.Page>
    </UserProfile>
  );
}
