import styles from "styles/ErrorPage.module.css";

const DEFAULT_ERROR_MESSAGE = "Oops! Something went wrong.";

export default function ErrorPage({ text, error, style }) {
  return (
    <div className={styles.container} style={style}>
      <div>
        <h1 className={styles.Message}> {text || DEFAULT_ERROR_MESSAGE} </h1>
        <p className={styles.subtitles}>
          {" "}
          If this error keeps happening, please contact us for assistance!{" "}
        </p>
      </div>
    </div>
  );
}
