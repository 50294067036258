import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "api/api";
import ChatBox from "components/chat/ChatBox";
import SideBar from "components/sidebar/SideBar";
import styles from "styles/Pages.module.css";
import Gradient from "components/common/Gradient";
import Spinner from "components/common/Spinner";
import ErrorPage from "pages/ErrorPage";

const ChatPage = () => {
  let { conversationId } = useParams();

  const queryClient = useQueryClient();
  const {
    data: conversations,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["conversations"],
    queryFn: api.fetchConversations,
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && conversations?.length > 0 && !conversationId) {
      // Select the first conversation by default
      navigate(`/chat/${conversations[0].convoId}`);
    }
  }, [conversationId, isLoading]);

  if (error) return <ErrorPage error={error} />;

  // Used to determine if the order needs to be updated once a new message is sent
  const selectedIsMostRecent =
    conversations?.length > 0 && conversations[0].convoId === conversationId;

  return (
    <div className={styles.chatPage}>
      <Gradient />
      <div className={styles.chatContainer}>
        <SideBar
          conversations={conversations}
          selectedConversationId={conversationId}
          isLoading={isLoading}
        />
        <ChatBox
          conversationId={conversationId}
          isMostRecentConversation={selectedIsMostRecent}
        />
      </div>
    </div>
  );
};

ChatPage.whyDidYouRender = true;
export default ChatPage;
