import React, { useEffect, useState } from "react";
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import BotAvatar from "assets/networkgpt.png";
import arrow from "assets/svgarrow.svg";
import { getCardComponent } from "components/cards/cardUtils";
import ThinkingIndicator from "components/common/ThinkingIndicator";
import ThinkingCursor from "components/common/ThinkingCursor";
import TextMessage from "./TextMessage";
import styles from "styles/Message.module.css";
import Card from "components/cards/Card";
import Button from "components/common/Button";
import { humanizedNum } from "components/cards/cardUtils";
import utils from "utils/utils";

// Todo remove later

export default function BotMessageItem({
  responseType,
  responseMessage,
  handleFollowup,
  showActions,
  initialCardsNum,
  actionType,
  selectedCardIds,
  handleCardSelect,
  loading,
  legacy,
  sendMessageFun,
  showFollowup,
  prompt,
}) {
  // TODO simplify and clean
  // This comes from our optimisitc update, while we get
  // the real data from the backend

  const [cardsInRow, setCardsInRow] = useState(utils.numCardsInRow);

  useEffect(() => {
    const handleResize = () => {
      setCardsInRow(utils.numCardsInRow);
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [numCardsToShow, setNumCardsToShow] = useState(initialCardsNum);

  const isCardType =
    responseType === "profiles" ||
    responseType === "jobs" ||
    responseType === "companies";

  let cardGroups = []; // make groups of 3 cards
  if (isCardType) {
    const cards = responseMessage[responseType].slice(0, numCardsToShow);
    while (cards.length) {
      cardGroups.push(cards.splice(0, cardsInRow));
    }
  }

  // TODO simplify things
  showActions = showActions && isCardType;
  const totalCount = isCardType && responseMessage[responseType].length;
  const canAskMore =
    isCardType && totalCount >= 4 && responseMessage?.numberResults >= 200;
  const canExpand =
    isCardType && numCardsToShow < responseMessage[responseType].length;

  //checkbox element for card selection
  return (
    <div className={styles.message}>
      <div
        className={styles.avatarContainer}
        style={{
          display: cardsInRow < 3 && isCardType ? "none" : "block",
        }}
      >
        <img src={BotAvatar} alt="User Avatar" />
        {/*<i className="fa-regular fa-phone-arrow-down-left"></i>*/}
      </div>

      {responseType === "loading" && <ThinkingIndicator />}
      {(responseType === "text" || responseType === "emails") && (
        <TextMessage content={responseMessage.content} />
      )}

      {isCardType && (
        <div className={styles.cardMessage}>
          <div className={styles.cardMessageHeader}>
            <div className={styles.estimatedResults}>
              <div
                className={styles.avatarContainer}
                style={{
                  display: cardsInRow < 3 ? "block" : "none",
                }}
              >
                <img src={BotAvatar} alt="User Avatar" />
                {/*<i className="fa-regular fa-phone-arrow-down-left"></i>*/}
              </div>
              <p>About {humanizedNum(responseMessage.numberResults)} results</p>
            </div>
            <span>
              <p>{responseMessage.pretext}</p>
            </span>
          </div>

          <div className={styles.cardMessageBody}>
            {cardGroups.map((group, groupIndex) => (
              <div className={styles.cardRow} key={groupIndex}>
                {group.map((item, index) => (
                  <Card
                    key={
                      item.id ||
                      item._id ||
                      //Todo remove in production
                      console.log("Can't get id for ", item)
                    }
                    useAsAskMore={
                      groupIndex * cardsInRow + index + 1 === totalCount &&
                      canAskMore
                    }
                    item={item}
                    responseType={responseType}
                    prompt={prompt}
                    sendMessageFun={sendMessageFun}
                  />
                ))}
              </div>
            ))}

            <div className={styles.actionArea}>
              {showActions && (
                <div className={styles.viewMoreAndFeedback}>
                  <div className={styles.viewMore}>
                    {canExpand && (
                      <Button
                        onClick={() => {
                          setNumCardsToShow(numCardsToShow + cardsInRow);
                        }}
                      >
                        <span>View more</span>
                      </Button>
                    )}
                  </div>

                  <div className={styles.feedback}>
                    <Button>
                      <span>
                        <FaThumbsUp style={{}} /> Like
                      </span>
                    </Button>

                    <Button>
                      <span>
                        <FaThumbsDown /> Dislike
                      </span>
                    </Button>
                  </div>
                </div>
              )}

              {showFollowup &&
                responseMessage.followupQuestions?.map((item) => (
                  <div className={styles.followup}>
                    <Button
                      onClick={() => {
                        handleFollowup(item, responseMessage);
                      }}
                    >
                      <div className={styles.followupContent}>
                        <span>{item.question}</span>
                        <img src={arrow} alt="arrow" height={15} />
                      </div>
                      {/*
                TODO maybe a longer icon 
                <i className="fa-sharp fa-solid fa-arrow-right-long"></i>
                */}
                    </Button>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
