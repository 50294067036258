import api from "api/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import utils from "utils/utils";
import { FetchError, getHumanReadableError } from "api/error";
import { useDevStore } from "store/store";
import { useAuth } from "@clerk/clerk-react";

export default function useSendMessage(conversationId, refetchConversations) {
  const queryClient = useQueryClient();
  const devMode = useDevStore((state) => state.devMode);
  const { getToken } = useAuth();

  const sendMessageMutation = useMutation({
    mutationFn: ({ inputPrompt, extra }) =>
      api.postNewMessage({
        conversationId,
        inputPrompt,
        extra,
        getToken,
      }),
    onMutate: async ({ inputPrompt }) => {
      /// For optimistic updates
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      const newMessage = {
        prompt: {
          promptId: utils.randomId(),
          content: inputPrompt,
          responseType: "loading",
        },
        converation: {
          convoId: conversationId,
        },
        response: {
          content: "loading",
        },
      };
      // Snapshot the previous value
      const previousMessages = queryClient.getQueryData([
        "conversations",
        conversationId,
      ]);
      queryClient.setQueryData(
        ["conversations", conversationId],
        (oldMessages) => [...oldMessages, newMessage]
      );
      return { previousMessages };
    },
    retry: (failureCount, error) => {
      //console.log("retrying", failureCount, error);
      if (failureCount === 0 && error instanceof FetchError) {
        console.log("Retrying because of Error", error);
        return true;
      } else {
        return false;
      }
    },
    retryDelay: 3000,
    onSuccess: (data, { inputPrompt }, context) => {
      queryClient.setQueryData(
        ["conversations", conversationId],
        [...context.previousMessages, data]
      );

      /*queryClient.invalidateQueries({
        queryKey: ["conversations", conversationId],
      });*/
    },
    onError: (err, { inputPrompt }, context) => {
      // TODO handle error
      console.log(err);
      const errorStr = getHumanReadableError(err, devMode);
      const newMessage = {
        prompt: {
          promptId: utils.randomId(),
          content: inputPrompt,
          responseType: "text",
        },
        converation: {
          convoId: conversationId,
        },
        response: {
          content: errorStr,
        },
      };
      // If there is an error fallback to the previous value + new
      // error message
      queryClient.setQueryData(
        ["conversations", conversationId],
        //context.previousMessages
        [...context.previousMessages, newMessage]
      );
    },

    onSettled: (data, error, { inputPrompt }) => {
      if (refetchConversations) {
        queryClient.invalidateQueries({ queryKey: ["conversations"] });
      }
    },
  });
  return sendMessageMutation;
}
