import userAvatar from "assets/catavatar.png";
import styles from "styles/Message.module.css";
export default function UserMessageItem({ prompt }) {
  return (
    <div
      className={` 
        ${styles.message} 
        ${styles.userMessage} 
        ${styles.textMessage}`}
    >
      <div className={styles.userContent}>
        <p>{prompt.content}</p>
      </div>
      <div className={`${styles.avatarContainer} ${styles.userAvatar}`}>
        <img src={userAvatar} alt="User Avatar" />
        {/*<i className="fa-regular fa-phone-arrow-up-right"></i>*/}
      </div>
    </div>
  );
}
