import { TbTruckReturn } from "react-icons/tb";
import Modal from "./Modal";
import { UserButton } from "@clerk/clerk-react";
import UserProfilePage from "pages/UserProfilePage";

export default function ProfileModal({ open, onClose }) {
  return (
    <Modal open={open} onClose={onClose}>
      <UserProfilePage />
    </Modal>
  );
}
