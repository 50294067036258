import React from "react";
import { BiCategory } from "react-icons/bi";
import { FaUser } from "react-icons/fa";

import { PiArrowUpRightBold, PiShootingStarFill } from "react-icons/pi";

import TextWithIcon from "components/common/TextWithIcon";

import { FaLocationPin } from "react-icons/fa6";
import { LinkWithIcon } from "components/common/LinkWithIcon";
import { getIndustryMessage, humanizedNum } from "./cardUtils";
import { CardInfoItem } from "./CardInfoItem";
import CardSection from "./CardSection";
import SocialIcons from "./SocialIcons";
import CardImage from "components/cards/CardImage";
import commonStyles from "styles/Card.module.css";
import CardHeader from "components/cards/CardHeader";
// TODO bottomarea and selected not used now, remove if not needed later

const getFundingMessage = (company) => {
  if (!company.funding?.hasRaised) return "";
  let category = company.funding?.category ?? "";
  // transform from series_e to Series E
  category = category.replaceAll("_", " ");
  category = category.split(" ");
  category = category
    .map((word) => {
      if (word === "ipo") {
        return "IPO";
      }

      return word[0].toUpperCase() + word.substring(1);
    })
    .join(" ");
  let raised = "Raised " + company.funding?.raisedFormatted + " total";
  if (company.funding?.category) {
    raised = raised + " and " + category + " stage";
  }

  return raised;
};

export default function CompanyCard({ company, bottomArea, selected }) {
  // if action type is not select, then we don't need to show some buttons
  // TODO use constants for action types
  // Todo customize card icons etc, once we know what we are sowing

  let category = company.funding?.category ?? "";

  return (
    <div style={{ height: "100%" }}>
      {/*<i className="bookmark fa-sharp fa-light fa-bookmark"></i>*/}
      <CardHeader image={company.logo}>
        <LinkWithIcon
          href={company.domain}
          text={company.name}
          textFontSize={15}
        />
        <TextWithIcon
          icon={<FaLocationPin fontSize={12} />}
          textFontSize={12}
          text={
            company.headquarters?.region + ", " + company.headquarters?.country
          }
        />
        <SocialIcons socialLinks={company.socialNetworks} />
      </CardHeader>

      <CardSection title="">{company.description}</CardSection>

      <CardSection title="Metrics">
        <CardInfoItem
          icon={<FaUser />}
          prefix={"Employees: "}
          text={company.metrics?.employeesRange}
        />
        <CardInfoItem
          prefix={"Web Rank: "}
          icon={<PiShootingStarFill />}
          text={humanizedNum(company.metrics?.globalRank)}
        />
        <CardInfoItem
          icon={<FaLocationPin />}
          text={company.metrics?.employees}
        />
        <CardInfoItem
          icon={<PiArrowUpRightBold />}
          prefix={"Revenue: $"}
          text={humanizedNum(company.metrics?.revenue)}
        />
        {company.industryMain && (
          <CardInfoItem
            icon={<BiCategory />}
            text={getIndustryMessage(company)}
          />
        )}

        <div style={{ textAlign: "center" }}>
          <LinkWithIcon
            href={company.socialNetworks?.crunchbase}
            text={getFundingMessage(company)}
            textFontSize={12}
          />
        </div>
      </CardSection>

      {bottomArea}
    </div>
  );
}
