import styles from "styles/CardSection.module.css";

export default function CardSection({ title, children }) {
  return (
    <div className={styles.container}>
      {/*
        <div className={styles.header}>
          <div className={styles.headerLine}></div>
          <h1 className={styles.headerText}>{title}</h1>
          <div className={styles.headerLine}></div>
        </div>
    */}
      <h1 className={styles.title}>{title}</h1>
      {<hr style={{ marginTop: -3, marginBottom: 7, color: "white" }} />}
      {children}
    </div>
  );
}
