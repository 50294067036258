import utils from "../utils/utils";
import metaProfiles from "./servermocks/metaProfiles.json";
import profiles from "./servermocks/profiles.json";

profiles = utils.tweakMocks(profiles, metaProfiles);

export const getAllProfileCards = () => {
  const message = getProfileMessage(Object.keys(profiles));
  message.response.followupQuestions = [];
  return message;
};

const getProfileMessage = (linkedinList, conversationId, promptId) => {
  conversationId = conversationId || utils.randomId();
  promptId = promptId || utils.randomId();
  let responseId = utils.randomId();
  const profileMessage = {
    conversation: {
      convoId: conversationId,
      created: "2023-05-23T10:40:10.284Z",
      updated: null,
      title: "Looking for Mr. Profile",
      userId: "2a94e40a-e4c7-4779-99o2-c5597396bbdd",
    },
    prompt: {
      promptId: promptId,
      convoId: conversationId,
      responseId: responseId,
      created: "2023-05-23T10:40:11.441Z",
      content:
        "Find entrepreneurs that founded a large technology company in Seattle",
      reponseType: "profiles",
    },
    response: {
      pretext: null,
      //"Respose for - Find entrepreneurs that founded a large technology company in Seattle",
      responseId: responseId,
      promptId: promptId,
      convoId: conversationId,
      created: "2023-05-23T10:40:12.999Z",
      responseType: "profiles",
      numberResults: 6,
      numberDisplayed: 3,
      actionsAvailable: true,
      preText: "Response for Find jobs for nodejs devs in Atlanta",
      postText: null,
      response: null,
      companies: [],
      followupQuestions: [
        {
          questionId: utils.randomId(),
          question:
            "Can you find me other entrepreneurs who also went to Harvard?",
          additionalQuestion:
            "Definitely! Select the job and I'll do my best to find the recruiters there.",
          action: "selector-single",
          preprompt: null,
          prompt:
            "Can you find me the recruiters from <employerName> (JobId: <jobId>)?",
        },
        {
          questionId: utils.randomId(),
          question: "Can you write me a cover letter for one of those jobs?",
          additionalQuestion:
            "Sure! Can you select the company and provide your linkedin profile? I can customize the letter based on your skills and experience?",
          action: "selector-single",
          preprompt: null,
          prompt:
            "Write a cover letter for the role from <employerName> (JobId: <jobId>)?",
        },
        {
          questionId: utils.randomId(),
          question: "Can you tell me if I would be a good fit for those roles?",
          additionalQuestion:
            "I'll do my best! Can you select the company and provide your linkedin profile?",
          action: "selector-single",
          preprompt: {
            display: "Linkedin URL - ",
            extractionRegex: "regex.to.extract.personal.linkedin",
          },
          prompt:
            "Here is my linkedin <extraction>, can you see if I am a good fit for the role from <employerName> (JobId: <jobId>)",
        },
      ],
      actions: [{ display: "View More", action: "view-more" }],
      profiles: linkedinList.slice(0, 1).map((linkedin) => profiles[linkedin]),
    },
  };
  return profileMessage;
};

export function getSimRecruitingMessages() {
  const messages = [
    {
      prompt: {
        content:
          "Find principle and distinguished engineers with 10+ years of experience in C++",
        simulated: true, // For simulation purposes on frontend
      },
      response: {
        responseType: "profiles",
        profiles: getProfileMessage([
          "wozniaksteve",
          "guido-van-rossum-4a0756",
          "brycelelbach",
        ]).response.profiles,
        followupQuestions: [
          {
            question: "Find other candidates who have attended UC Berkeley",
            action: "",
          },
          {
            question: "Find similar engineers who have hardware experience",
            action: "",
          },
        ],
      },
    },
    {
      prompt: {
        content: "Tell me more about Steve Wozniak",
        simulated: true,
      },
      response: {
        content:
          "Steve Wozniak, one of the co-founders of Apple, is an award-winning electronics engineer, with a Master's in Computer Science from UC Berkeley. Expert in hardware technologies, with extensive experience leading engineering teams at Apple and SanDisk.",
        responseType: "text",
      },
    },
    {
      prompt: {},
      response: {
        content: "Sure, here you go - steve@woz.org",
        responseType: "text",
      },
    },
    {
      prompt: {
        content:
          "Please write an email to recruit Steve Wozniak for my AI robotics startup",
        simulated: true,
      },
      response: {
        content: `<code>
        Hi Steve,

        [Your AI Startup] is a robotics hardware startup pioneering ways to amplify human potential through AI.
        
        I believe your unique perspective, deep technical expertise and passion for innovating, could help shape our future.
        
        I'd love to discuss an exciting engineering role mentoring our talented team to build transformative technologies.
        
        If interested, let's find time to connect. I'm confident your contributions here could positively impact the world.
        
        Hope to speak soon,
        [Your Name]
        [Your AI Startup]

        P.S. Your work on the Apple II has always inspired me.

        </code>
        `,
        responseType: "text",
      },
    },
  ];
  return utils.fillIdFields(messages);
}

export function getSimInvestorMessages() {
  const messages = [
    {
      prompt: {
        content:
          "Find executives with AI experience currently working in Stealth Startup mode",
        simulated: true, // For simulation purposes on frontend
      },
      response: {
        responseType: "profiles",
        profiles: getProfileMessage([
          "george-hotz-b3866476",
          "jimbkeller",
          "mustafa-suleyman",
        ]).response.profiles,
        followupQuestions: [
          {
            question:
              "Filter the results by executives who have worked at Google before",
            action: "",
          },
          {
            question:
              "Filter the results by executives who have worked at Apple before",
            action: "",
          },
        ],
      },
    },
    {
      prompt: {},
      response: {
        responseType: "profiles",
        profiles: getProfileMessage(["mustafa-suleyman"]).response.profiles,
        followupQuestions: [],
      },
    },
    {
      prompt: {
        content: "Tell me more about Mustafa Suleyman",
        simulated: true,
      },
      response: {
        content: `Mustafa Suleyman was a co-founder of DeepMind, acquired by Google in 2014 and has received the prestigious Silicon Valley Visionary Award. He is recognized for his expertise in AI, and is also a member of The Economist's Board. He is now the Co-founder of a Stealth AI Startup.`,
        responseType: "text",
      },
    },
    {
      prompt: {},
      response: {
        responseType: "text",
        content: "Sure here you go - mustafa@inflection.ai",
      },
    },
    {
      prompt: {
        content:
          "Write an email template to introduce Mustafa Suleyman to my firm NFX",
        simulated: true,
      },
      response: {
        content: `<code>
        Dear Mustafa,

    I am a [Your Position] at NFX, a VC firm known for pioneering investments in businesses which have strong Network Effects. Our potfolio includes Doordash, Patreon, and Lyft amongst others [insert link]. 
    
    We have a strong focus on artificial intelligence, were impressed by your background and would love to learn what you are building next. We love taking early bets with visionary founders.
    
    Are you able to connect this week?
    
    Thank you
    
    Best regards,
    [Your Name]
    [Your Position]
    NFX
        </code>
        `,
      },
    },
  ];

  return utils.fillIdFields(messages);
}

export function getSimEntrepreneurMessages() {
  const messages = [
    {
      prompt: {
        content:
          "Find investors that are also celebrities that have invested in early stage startups recently",
        simulated: true, // For simulation purposes on frontend
      },
      response: {
        responseType: "profiles",
        profiles: getProfileMessage([
          "jessica-alba",
          "vancityreynolds",
          "ashton-kutcher-849288a2",
        ]).response.profiles,
        followupQuestions: [
          {
            question: "Filter by people who have FinTech experience",
            action: "",
          },
          {
            question: "Filter by investors that live in Los Angeles",
            action: "",
          },
        ],
      },
    },
    {
      prompt: {},
      response: {
        responseType: "profiles",
        profiles: getProfileMessage(["jessica-alba", "ashton-kutcher-849288a2"])
          .response.profiles,
        followupQuestions: [
          {
            question: "Filter by investors that have experience in e-commerce",
            action: "",
          },
          {
            question: "Find similar investor profiles that live in New York",
            action: "",
          },
        ],
      },
    },
    {
      prompt: {},
      response: {
        responseType: "profiles",
        profiles: getProfileMessage(["jessica-alba"]).response.profiles,
        followupQuestions: [],
      },
    },
    {
      prompt: {},
      response: {
        responseType: "text",
        content: "Sure here you go - jessica.alba@honest.com",
      },
    },
  ];
  return utils.fillIdFields(messages);
}

export function getSimSalesMessages() {
  const messages = [
    {
      prompt: {
        content:
          "Find C-level executives at large tech companies (>500 people) in California",
        simulated: true, // For simulation purposes on frontend
      },
      response: {
        responseType: "profiles",
        profiles: getProfileMessage([
          "brianchesky",
          "juliahartz",
          "reedhastings",
          "marissamayer",
          "ericsyuan",
          "bawotona",
        ]).response.profiles,
        followupQuestions: [
          {
            question:
              "Find executives at companies doing between $100M and $1B in revenue per year",
            action: "",
          },
          {
            question:
              "Find C-level executives working at tech companies in New York",
            action: "",
          },
        ],
      },
    },
    {
      prompt: {},
      response: {
        responseType: "profiles",
        profiles: getProfileMessage([
          "anjalisud",
          "sarah-kirshbaum-levy-889881a1",
          "dittycheria",
        ]).response.profiles,
        followupQuestions: [
          {
            question:
              "Filter results by executives who have worked previously at Amazon",
            action: "",
          },
          {
            question: "Filter results by executives who have attended Harvard",
            action: "",
          },
        ],
      },
    },
    {
      prompt: {},
      response: {
        responseType: "profiles",
        profiles: getProfileMessage(["anjalisud"]).response.profiles,
        followupQuestions: [],
      },
    },
    {
      prompt: {
        content: "Tell me more about Anjali Sud",
        simulated: true,
      },
      response: {
        content:
          "Anjali Sud is Vimeo's CEO. With accolades like Fortune India's Most Powerful Women, she's renowned for leadership, inclusivity, and productivity. Her workplace insights are shared widely in Time, The New York Times, and beyond.",
        responseType: "text",
      },
    },
    {
      prompt: {},
      response: {
        content: `Sure here you go - anjali.sud@vimeo.com`,
        responseType: "text",
      },
    },
    {
      prompt: {
        content:
          "Write an email with an engaging subject line to Anjali to sell my GPT based sales intelligence sidekick",
        simulated: true,
      },
      response: {
        content: `<code> Subject line: Skyrocket Vimeo's sales process one query at a time
        Hi Anjali,

        I know you're looking for ways to accelerate Vimeo's growth. 
        
        Our AI sales assistant, [Product Name], could significantly boost your sales pipeline by automating the tedious process of prospecting, engaging and converting leads into customers.
        
        I'd love to schedule a quick 15-minute demo this week to show you [Product Name] in action.
        
        Looking forward to it! 
        
        [Your Name]
        [Title, Company]
        </code>`,
      },
    },
  ];
  return utils.fillIdFields(messages);
}

export const getRandomMessageData = () => getProfileMessage();

export { getProfileMessage };
