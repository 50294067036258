// For replacing placeholders in the prompt messages

import Toastify from "toastify-js";
import sanitizeHtml from "sanitize-html";

// messageType is one of , 'error', 'success', 'warning'
function notify(message, messageType = "error") {
  console.error(message); // Also log to console

  Toastify({
    text: message.toString(),
    duration: 3000,
    newWindow: true,
    close: true,
    gravity: "top", // `top` or `bottom`
    position: "center", // `left`, `center` or `right`
    stopOnFocus: true, // Prevents dismissing of toast on hover
    style: {
      background: "linear-gradient(to right, #ac87bb, #8b3dff)", // TODO move to style.css
    },
    onClick: function () {}, // Callback after click
  }).showToast();
}
function replacePlaceholders(match, key, obj) {
  if (obj.hasOwnProperty(key)) {
    return obj[key].toString();
  } else {
    return match;
  }
}
function getFormattedPrompt(prompt, obj) {
  let pattern = /<([^>]+)>/g;
  let formattedString = prompt.replace(pattern, (match, key) =>
    replacePlaceholders(match, key, obj)
  );
  return formattedString;
}

function sortProfileSkills(skills) {
  if (!skills) return [];
  // Sort skills by decreasing years of experience
  skills.sort((a, b) => {
    return b.years - a.years;
  });
  return skills;
}

function randomId() {
  const length = 9;
  return String(Math.floor(Math.random() * 1e9)).padStart(length, "0");
}

function randomDate(future = false) {
  var currentDate = new Date();
  var offset = -2;
  if (future) offset = -offset;
  var randomDays = Math.floor(Math.random() * offset);
  currentDate.setDate(currentDate.getDate() + randomDays);
  return currentDate;
}

function extractSubstring(str, regexString) {
  const regex = new RegExp(regexString);
  const match = str.match(regex);
  return match ? match[0] : null;
}

function sleep(msecs = 100) {
  return new Promise((resolve) => setTimeout(resolve, msecs));
}

// fills id fields with random strings for testing purposes
function fillIdFields(messages) {
  for (let message of messages) {
    message.prompt.promptId = randomId();
  }
  return messages;
}

function humanizedWorkYears(start, end) {
  // start and end are strings like "2019-01"
  // we are extracting only last 2 digits of the year for each date
  if (!start || !end) return "";
  const startYear = start.slice(2, 4);
  let endYear = end;
  if (endYear != "present") endYear = end.slice(2, 4);

  return `${startYear}-${endYear}`;
}

function sanitize(dirty) {
  const allowedTags = ["b", "i", "em", "strong", "a", "li"];
  return sanitizeHtml(dirty, {
    allowedTags,
  });
}

const tweakMocks = (items, metaItems) => {
  const tweakedProfiles = {};
  // merge profiles and metaProfiles, metaprofile takes precedence

  Object.keys(items).forEach((itemId) => {
    const item = items[itemId];
    const metaItem = metaItems[itemId];
    // Fake socials for profiles
    const fakedSocials = { facebook: "akk", crunchbase: "bb" };
    const newItem = { ...fakedSocials, ...item, ...metaItem, id: randomId() };
    if (!newItem.linkedinUrl) newItem.linkedinUrl = newItem.linkedin;

    if (Object.keys(newItem).length > 0) tweakedProfiles[itemId] = newItem;
  });

  return tweakedProfiles;
};

const toReadableDate = (timestamp) => {
  const dateObj = new Date(timestamp);
  return dateObj.toLocaleString("en-US", {
    dateStyle: "medium",
    timeStyle: "short",
  });
};

const screenSmallerThan = (width) => {
  // Mobile devices and small tablets are considered small screens
  return window.innerWidth < width;
};

const isMobileDevice = () => {
  return screenSmallerThan(1400);
};

const numCardsInRow = () => {
  // Calculate how many cards to show in a row
  if (screenSmallerThan(700)) return 1;
  if (screenSmallerThan(1110)) return 2;
  return 3;
};

const utils = {
  getFormattedPrompt,
  notify,
  randomId,
  randomDate,
  extractSubstring,
  sleep,
  fillIdFields,
  sanitize,
  tweakMocks,
  sortProfileSkills,
  humanizedWorkYears,
  toReadableDate,
  screenSmallerThan,
  isMobileDevice,
  numCardsInRow,
};
export default utils;
