import TextWithIcon from "../common/TextWithIcon";

export function CardInfoItem({ icon, text, prefix }) {
  if (!text) return null;
  if (!prefix) prefix = "";
  return (
    <div>
      <TextWithIcon
        icon={icon}
        textFontSize={13}
        iconFontSize={12}
        text={<span className="card-section-text">{prefix + text}</span>}
      ></TextWithIcon>
    </div>
  );
}
