import styles from "styles/CheckboxField.module.css";
export default function CheckboxField({ label, checked, onChange }) {
  return (
    <label className={styles.container}>
      {label}
      <input type="checkbox" onChange={onChange} checked={checked} />
      <span className={styles.checkmark}></span>
    </label>
  );
}
