import UserArea from "components/sidebar/UserArea";
import DebugArea from "components/common/DebugArea";
import { useUserStore } from "store/store";
import { useEffect, useState } from "react";
import styles from "styles/Sidebar.module.css";
import Button from "components/common/Button";
import { useNavigate } from "react-router-dom";
import TextWithIcon from "components/common/TextWithIcon";
import { FaPlus } from "react-icons/fa";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api/api";
import EditableTitle from "components/sidebar/EditableTitle";
import utils from "utils/utils";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function SideBar({
  conversations,
  selectedConversationId,
  isLoading,
}) {
  const showSidebar = useUserStore((state) => state.showSidebar);
  const setShowSidebar = useUserStore((state) => state.setShowSidebar);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [editingTitleId, setEditingTitleId] = useState(null);
  const [editingTitle, setEditingTitle] = useState("");

  const renameMutation = useMutation({
    mutationFn: api.renameConversation,
    onSuccess: (data) => {
      // data is the new conversation object
      //queryClient.invalidateQueries({ queryKey: ["conversations"] });
    },
    onMutate: async ({ convoId, newTitle }) => {
      // Snapshot the previous value
      const previousConversations = queryClient.getQueryData(["conversations"]);
      queryClient.setQueryData(["conversations"], (oldConversations) => {
        const newConversations = oldConversations.map((c) => {
          if (c.convoId === convoId) {
            return {
              ...c,
              title: newTitle,
            };
          } else {
            return c;
          }
        });
        return newConversations;
      });
      return { previousConversations };
    },

    onError: (err, { inputPrompt }, context) => {
      utils.notify("Renaming failed");
      queryClient.invalidateQueries({ queryKey: ["conversations"] });
    },
  });

  const onRenameEnd = (convoId) => {
    if (editingTitleId === convoId) {
      renameMutation.mutate({
        convoId,
        newTitle: editingTitle,
      });
      setEditingTitleId(null);
    }
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1400px)");

    const handleResize = () => {
      setShowSidebar(mediaQuery.matches);
    };
    mediaQuery.addListener(handleResize);

    return () => mediaQuery.removeListener(handleResize);
  }, []);

  const handleConvoClick = (event, convoId) => {
    event.preventDefault();
    if (utils.isMobileDevice()) {
      setShowSidebar(false);
    }
    if (event.detail === 1) navigate(`/chat/${convoId}`);
    else if (event.detail === 2) {
      setEditingTitleId(convoId);
      setEditingTitle(conversations.find((c) => c.convoId === convoId).title);
    }
  };

  const handleNewClick = (event) => {
    event.preventDefault();
    if (utils.isMobileDevice()) {
      setShowSidebar(false);
    }
    navigate("/chat/new");
  };

  const handleKeyDown = (event, convoId) => {
    if (event.key === "Enter") {
      onRenameEnd(convoId);
    }
  };

  return (
    <>
      <div
        className={`${styles.hamburgerIcon} ${
          showSidebar ? styles.hamburgerRotated : ""
        }`}
        onClick={() => {
          setShowSidebar(!showSidebar);
        }}
      >
        <i className="fa-solid fa-bars"></i>
      </div>
      <div
        className={`box ${styles.container} ${
          !showSidebar ? styles.hidden : styles.visible
        }`}
      >
        <div className={styles.newSearch}>
          <Button
            onClick={handleNewClick}
            selected={selectedConversationId === "new"}
            type="dashed"
          >
            <i className="fa-regular fa-plus"></i>
            <span> New Search </span>
          </Button>
        </div>
        <div className={styles.conversations}>
          {isLoading ? (
            <SkeletonTheme
              baseColor="var(--colour-1)"
              highlightColor="rgba(255, 255, 255, 0.3)"
              height={20}
            >
              {Array.from({ length: 20 }).map(() => (
                <p>
                  <Skeleton />
                </p>
              ))}
            </SkeletonTheme>
          ) : (
            conversations.map((conversation) => (
              <Button
                key={conversation.convoId}
                type="clear"
                selected={selectedConversationId === conversation.convoId}
                onClick={(event) =>
                  handleConvoClick(event, conversation.convoId)
                }
              >
                <i className="fa-regular fa-comments"></i>
                <span className={styles.title}>
                  <EditableTitle
                    type="text"
                    value={
                      conversation.convoId === editingTitleId
                        ? editingTitle
                        : conversation.title
                    }
                    onChange={(e) => setEditingTitle(e.target.value)}
                    onRenameEnd={() => onRenameEnd(conversation.convoId)}
                    readOnly={conversation.convoId !== editingTitleId}
                    onKeyDown={(event) =>
                      handleKeyDown(event, conversation.convoId)
                    }
                  />
                </span>
              </Button>
            ))
          )}
        </div>
        <UserArea />
      </div>
    </>
  );
}
