import { useEffect } from "react";
import styles from "styles/Modal.module.css";
import { IoClose } from "react-icons/io5";

const Modal = ({ open, onClose, children }) => {
  useEffect(() => {
    window.addEventListener("keydown", handleEscapeKeyDown);

    return () => {
      window.removeEventListener("keydown", handleEscapeKeyDown);
    };
  }, []);

  const handleEscapeKeyDown = (event) => {
    if (event.key === "Escape") {
      onClose();
    }
  };

  if (!open) return null;

  return (
    <>
      <div className={styles.overlay} onClick={onClose} />
      <div className={styles.modal}>
        {children}
        <div className={styles.closeIcon} onClick={onClose}>
          <IoClose color="black" />
        </div>
      </div>
    </>
  );
};

export default Modal;
