import { IconContext } from "react-icons";

export default function TextWithIcon({
  icon,
  text,
  iconLeft = true,
  iconFontSize = 13,
  textFontSize = 13,
  textClassName,
  islink,
}) {
  if (!text) return null;
  return (
    <IconContext.Provider
      value={{
        style: {
          fontSize: iconFontSize,
          verticalAlign: "middle",
          marginBottom: 2, //TODO icon centering hack figure out why it's needed
        },
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ display: "inline-block" }}>{iconLeft && icon}</div>
        <span
          className={textClassName}
          style={{
            marginLeft: iconLeft ? 5 : 0,
            marginRight: 5,
            fontSize: textFontSize,
          }}
        >
          {text} {!iconLeft && text && icon}
        </span>
      </div>
    </IconContext.Provider>
  );
}
