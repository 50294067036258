export const jobs = {
  sofi: {
    id: "123123123",
    title: "Senior Flutter Engineer",
    location: "San Francisco",
    timestamp: "2023-06-28T03:58:55.251Z",
    timeAgo: "4 days ago",
    companyInfo:
      "SoFi provides a suite of lending, and investment products focused on helping users take control of their finances.",
    contractType: "Full–time",
    sourceName: "via ziprecruiter.com",
    sourceUrl:
      "https://virtualjobs.usnlx.com/atlanta-ga/nodejs-developer-remote/7F00262732D241CBA52F9E51004D2258/job/",
    employerName: "SoFi",
    employerUrl: "https://sofi.com/",
    employerImageUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGSkN6o0lWLXQWQdZWkmkPDNePTvPpiatKH5C8g5E&s",
    jobInfo: [
      `Senior Flutter Developer for mobile app enhancement. Opportunity to collaborate in a high-impact role across product teams, driving solutions from architecture to implementation.
    `,
      "Opportunity to collaborate in a high-impact role across product teams, driving solutions from architecture to implementation",
    ],
    qualifications: [
      "Bachelor’s in Computer Science or equivalent, with 4+ years of Flutter experience.",
      "Proficiency in Git, strong fundamentals, test-driven development, and passion for problem-solving.",
    ],

    compensation: "$75 an hour",
    salary: "$75 an hour",
  },
  bitfinex: {
    id: "1231fafaf3",
    title: "Android Software Engineer",
    location: "Remote",
    timestamp: "2023-06-28T03:58:55.251Z",
    timeAgo: "11 days ago",
    contractType: "Full-time",
    sourceName: "via remoters.net",
    companyInfo:
      "Bitfinex is a cryptocurrency exchange platform offering trading for major digital currencies as well as leverage margin trading and lending.",
    sourceUrl:
      "https://www.indeed.com/viewjob?jk=829d888c57f16f73&tk=1h6m3qfhpih61800&from=serp&vjs=3",
    employerName: "Bitfinex",
    employerUrl: "https://www.jpmorganchase.com/",
    employerImageUrl: "https://bitfinex.com/images/thumbnails/bitfinex-1.png",
    jobInfo: [
      "As a Mobile-App Developer, you will be a part of the team that works on the development of the Bitfinex mobile applications. This includes: New Features - Bitfinex continuously strives to create innovative new features in order to provide the most professional trading experience for customers. These are developed and deployed using agile methodologies, to ensure the user experience remains the best in the industry.",
    ],
    qualifications: [
      "Bachelor’s in Computer Science or equivalent, with 5+ years of experience with React, React-Native, Redux",
    ],
    compensation: "$5,000 - $7,000",
    salary: "$5,000 - $7,000",
  },
  dropbox: {
    id: "1231fafxxf3",
    title: "Software Engineer",
    location: "Remote",
    timestamp: "2023-06-28T03:58:55.251Z",
    timeAgo: "10 days ago",
    contractType: "Full-time",
    sourceName: "via glassdoor.com",
    sourceUrl:
      "https://www.indeed.com/viewjob?jk=829d888c57f16f73&tk=1h6m3qfhpih61800&from=serp&vjs=3",
    employerName: "Dropbox",
    employerUrl: "https://www.jpmorganchase.com/",
    companyInfo:
      "Dropbox is a file hosting service that offers cloud storage, file synchronization, personal cloud, and client software across platforms.",
    employerImageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Dropbox_Icon.svg/2202px-Dropbox_Icon.svg.png",
    jobInfo: [
      "Dropbox is looking for an engineer to step in and bring our mobile infrastructure team to the next level. The ideal candidate will have experience with Gradle and various CI/CD tools such as Buildkite, as well as some Kotlin or Java knowledge. ",
    ],
    qualifications: [
      "Bachelor's or higher in Computer Science or related technical field",
      "Experienced with Gradle and various CI/CD tools such in Kotlin or Java.",
    ],
    compensation: "<li> $123,500 - $180,000",
    salary: "$150K - $175K",
  },
  jpmorgan: {
    id: "1241fasf",
    externalId: null,
    title: "Senior React Native engineer",
    location: "Remote",
    timestamp: "2023-06-28T03:58:55.251Z",
    timeAgo: "3 days ago",
    contractType: "Full-time",
    sourceName: "via indeed.com",
    companyInfo:
      "JP Morgan is an investment banking, asset management, private banking and retail financial services globally",
    sourceUrl:
      "https://www.indeed.com/viewjob?jk=829d888c57f16f73&tk=1h6m3qfhpih61800&from=serp&vjs=3",
    employerName: "JPMorgan Chase & Co",
    employerUrl: "https://www.jpmorganchase.com/",
    employerImageUrl:
      "https://d2q79iu7y748jz.cloudfront.net/s/_squarelogo/256x256/9931bd53a27e29f89bbb63fe8aa82103",
    jobInfo: [
      "We’re seeking a Senior Mobile Engineer to join our growing mobile team. As a Senior Engineer, your day-to-day work will focus on writing great code using modern tools such as React Native, Bitrise, GraphQL, and Redux Toolkit. You’ll work with a highly collaborative team of engineers, designers, and product managers to build exciting new features in our application, and ultimately service our users, helping them to discover new restaurants. You’ll also help us set best practices for writing code and growing/maintaining our codebase. This role will report to an Engineering Manager and be responsible for planning and contributing high-quality code to our codebase. We are looking for candidates who are willing to work East Coast hours of 9am-5pm EST.",
    ],
    qualifications: [
      `Skilled mobile app dev, 4+ years of software experience, including React Native, Flutter, or Xamarin`,
      "Proficiency with CI/CD pipelines, E2E (end to end) tests, A/B testing, native development",
    ],
    compensation: "$123,500 - $180,000",
    salary: "$120K - $180K",
  },

  mobilecoin: {
    id: "1231fafxtqw3",
    externalId: null,
    title: "Flutter Engineer",
    location: "San Francisco",
    timestamp: "2023-06-28T03:58:55.251Z",
    timeAgo: "2 days ago",
    contractType: "Full-time",
    sourceName: "via glassdoor.com",
    sourceUrl: "https://us.jobrapido.com/jobpreview/2937081933",
    employerName: "Insight Global",
    employerUrl: "http://www.insightglobal.com/",
    employerImageUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMcX2i5ZfyRL1M03It8DDmPwesQfAIHwqILaaCQI0&s",
    jobInfo: [
      `Say hello to MobileCoin, the only sustainable payments platform that's fast and simple enough for everyday private payments.
      We've developed a fully private, distributed payments platform you can use every day to pay for anything while protecting your privacy and data. Though based on a cryptocurrency, the barrier to understanding, buying, and using crypto is huge for many, and we want to remove`,
    ],
    qualifications: [
      `Experienced Flutter dev (4+ yrs) with solid Dart skills, proficient in cross-platform UI (iOS/Android).`,
      `Expert in large scale app deployments in App Store/Google Play and collaborating with platorm team`,
    ],
    compensation: "$160,000-$200,000",
    salary: "$160K - $200K",
    companyInfo:
      "MobileCoin is a privacy-focused cryptocurrency enabling speedy, end-to-end encrypted p2p payments.",
  },
  google: {
    id: "sfa123fasfa",
    externalId: null,
    title: "Android Software Engineer",
    location: " NY, USA",
    timestamp: "2023-06-28T03:58:55.251Z",
    timeAgo: "5 days ago",
    contractType: "Full-time",
    sourceName: "via geebo.com",
    sourceUrl: "https://us.jobrapido.com/jobpreview/2937081933",
    employerName: "Google",
    employerUrl: "http://www.insightglobal.com/",
    employerImageUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNDBsOjXiogo6sOzNFsAhNdEqXUy-93dC_SDpq&s=0",
    jobInfo: [
      `Google specializes in large-scale products in search, advertising, cloud, software and hardware.`,
    ],
    qualifications: [
      `Bachelor’s in Computer Sci. or equivalent, with 5+ years of experience. Strong data structures/algorithms skills.`,
      `Proficiency in Flutter, versatile and good sense of API design`,
    ],
    companyInfo:
      "Google specializes in large-scale products in search, advertising, cloud, software and hardware.",
    compensation: "$133,000-$194,000",
    salary: "$130K-$195K",
  },
};
