import TextWithIcon from "./TextWithIcon";
import { FaUpRightFromSquare } from "react-icons/fa6";

export const LinkWithIcon = ({ href, text, textFontSize }) => (
  <a href={href} target="_blank" rel="noreferrer">
    <TextWithIcon
      icon={<FaUpRightFromSquare fontSize={textFontSize} />}
      textFontSize={textFontSize}
      text={text}
      iconLeft={false}
    />
  </a>
);
