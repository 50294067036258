import { RedirectToSignIn, SignedIn, SignedOut } from "@clerk/clerk-react";

// Temporarily disable protected routes
/*export default function Protected({ children }) {
  return <>{children}</>;
}*/

export default function Protected({ children }) {
  return (
    <>
      <SignedIn>{children}</SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </>
  );
}
