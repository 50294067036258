import styles from "styles/ProgressBar.module.css";
import tooltipStyles from "styles/CreditTooltip.module.css";
import CreditTooltip from "components/sidebar/CreditTooltip";

export default function ProgressBar({ value, max }) {
  return (
    <div className={`${styles.container} ${tooltipStyles.tooltip}`}>
      <progress value={value} max={max} className={styles.progressBar}>
        75%
      </progress>
      <CreditTooltip />
    </div>
  );
}
