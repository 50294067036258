export default function ParagraphInfo({ items }) {
  return (
    <div className="paragraph-info">
      {items?.map((item, index) => (
        <div key={index}>
          <p>{item}</p>
          <br />
        </div>
      ))}
    </div>
  );
}
