export const mockConversationsData = {
  success: true,
  totalCount: 72,
  totalPages: 8,
  results: [
    {
      id: "live_one",
      created: "2023-06-25T02:16:31.526Z",
      updated: "2023-06-25T02:16:31.526Z",
      userId: "2a94e40a-e4c7-4779-9902-c5597396bbdd",
      title: "live chat 1",
    },
    {
      id: "live_two",
      created: "2023-06-25T02:16:31.526Z",
      updated: "2023-06-25T02:16:31.526Z",
      title: "Live chat 2",
      userId: "2a94e40a-e4c7-4779-9902-c5597396bbdd",
    },
    {
      id: "allcards",
      created: "2023-06-26T01:28:29.070Z",
      updated: "2023-06-26T01:28:29.070Z",
      title: "All Cards",
      userId: "2a94e40a-e4c7-4779-99o2-c5597396bbdd",
    },
    {
      id: "dev",
      created: "2023-06-25T02:16:31.526Z",
      updated: "2023-06-25T02:16:31.526Z",
      title: "DEVELOPMENT - Conversation For Development",
      userId: "2a94e40a-e4c7-4779-9902-c5597396bbdd",
    },
    {
      id: "icprecruiting",
      created: "2023-06-25T02:16:31.526Z",
      updated: "2023-06-25T02:16:31.526Z",
      title: "ICP-RECRUITING",
      userId: "2a94e40a-e4c7-4779-9902-c5597396bbdd",
    },
    {
      id: "icpsales",
      created: "2023-06-25T02:16:31.526Z",
      updated: "2023-06-25T02:16:31.526Z",
      title: "ICP-SALES",
      userId: "2a94e40a-e4c7-4779-9902-c5597396bbdd",
    },
    {
      id: "icpinvestors",
      created: "2023-06-25T02:16:31.526Z",
      updated: "2023-06-25T02:16:31.526Z",
      title: "ICP-INVESTORS",
      userId: "2a94e40a-e4c7-4779-9902-c5597396bbdd",
    },
    {
      id: "icpentrepreneurs",
      created: "2023-06-25T02:16:31.526Z",
      updated: "2023-06-25T02:16:31.526Z",
      title: "ICP-ENTREPRENEUR",
      userId: "2a94e40a-e4c7-4779-9902-c5597396bbdd",
    },
    {
      id: "icpcompanysales",
      created: "2023-06-25T02:16:31.526Z",
      updated: "2023-06-25T02:16:31.526Z",
      title: "ICP-COMPANY-SALES",
      userId: "2a94e40a-e4c7-4779-9902-c5597396bbdd",
    },
    {
      id: "icpjobs",
      created: "2023-06-25T02:16:31.526Z",
      updated: "2023-06-25T02:16:31.526Z",
      title: "ICP-JOBS",
      userId: "2a94e40a-e4c7-4779-9902-c5597396bbdd",
    },
    {
      id: "random",
      created: "2023-06-25T02:16:31.526Z",
      updated: "2023-06-25T02:16:31.526Z",
      title:
        "RANDOM convo, include company, job ... in your query text and get a corresponding result",
      userId: "2a94e40a-e4c7-4779-9902-c5597396bbdd",
    },
    {
      id: "76",
      created: "2023-06-25T02:02:27.372Z",
      updated: "2023-06-25T02:02:27.372Z",
      title: "Find software engineers from Apple in Cupertino",
      userId: "2a94e40a-e4c7-4779-9902-c5597396bbdd",
    },
    {
      id: "75",
      created: "2023-06-25T01:53:52.591Z",
      updated: "2023-06-25T01:53:52.591Z",
      title: "Find software engineers from Apple",
      userId: "2a94e40a-e4c7-4779-9902-c5597396bbdd",
    },
    {
      id: "74",
      created: "2023-06-24T21:50:04.492Z",
      updated: "2023-06-24T21:50:04.492Z",
      title: "Find email for Pablo Tapia from Microsoft",
      userId: "2a94e40a-e4c7-4779-9902-c5597396bbdd",
    },
    {
      id: "73",
      created: "2023-06-24T21:49:09.549Z",
      updated: "2023-06-24T21:49:09.549Z",
      title: "Find email for Pablo Tapia from Microsoft",
      userId: "2a94e40a-e4c7-4779-9902-c5597396bbdd",
    },
    {
      id: "72",
      created: "2023-06-24T21:47:26.473Z",
      updated: "2023-06-24T21:47:26.473Z",
      title: "Find email for Pablo Tapia from Microsoft",
      userId: "2a94e40a-e4c7-4779-9902-c5597396bbdd",
    },
    {
      id: "71",
      created: "2023-06-24T21:41:45.943Z",
      updated: "2023-06-24T21:41:45.943Z",
      title: "Find email for Pablo Tapia from Microsoft",
      userId: "2a94e40a-e4c7-4779-9902-c5597396bbdd",
    },
    {
      id: "70",
      created: "2023-06-24T21:36:06.857Z",
      updated: "2023-06-24T21:36:06.857Z",
      title: "Find email for Pablo Tapia from Microsoft",
      userId: "2a94e40a-e4c7-4779-9902-c5597396bbdd",
    },
    {
      id: "69",
      created: "2023-06-24T21:18:57.768Z",
      updated: "2023-06-24T21:18:57.768Z",
      title: "Find email for Pablo Tapia from Microsoft",
      userId: "2a94e40a-e4c7-4779-9902-c5597396bbdd",
    },
    {
      id: "68",
      created: "2023-06-24T21:14:28.265Z",
      updated: "2023-06-24T21:14:28.265Z",
      title: "Find software engineers from Goldman Sachs",
      userId: "2a94e40a-e4c7-4779-9902-c5597396bbdd",
    },
  ],
};

/* TODO ZI probably not needed remove later 

import { mockCompaniesData } from "./mockCompanies";

export function getMockConversationData(conversationId) {
  const mockRes = {
    success: true,
    totalCount: 3,
    totalPages: 1,
    results: [
      {
        id: 81,
        created: "2023-06-26T01:28:29.070Z",
        updated: "2023-06-26T01:28:29.070Z",
        content: "find me the email for pablo tapia from microsoft",
        reponseType: "emails",
        conversationId: 77,
        responseId: null,
        response: {
          preText: "",
        },
      },
      {
        id: 78,
        created: "2023-06-25T02:16:32.571Z",
        updated: "2023-06-25T02:16:32.571Z",
        content:
          "find me software engineers from salesforce that live in california",
        reponseType: "profiles",
        conversationId: 77,
        responseId: null,
        response: { preText: "" },
        tole: "assistant",
      },
      {
        id: 100,
        created: "2023-06-09T15:28:15.409Z",
        updated: "2023-06-09T15:28:23.364Z",
        content: "I would like to find companies in Seattle",
        reponseType: "companies",
        conversationId: "77",
        responseId: 100,
        response: {
          id: 100,
          created: "2023-06-09T15:28:17.119Z",
          updated: "2023-06-09T15:28:17.119Z",
          responseType: "companies",
          numberResults: 3,
          numberDisplayed: 3,
          actionsAvailable: true,
          preText: "Response for I would like to find companies in Seattle",
          postText: null,
          response: null,
          followUpQuestions: [
            "Would you like to find similar companies in the U.S.?",
            "Find sales leaders in the above companies?",
            "Find the email address of the CEO in one of the above companies?",
          ],
          companies: mockCompaniesData,
        },
        tole: "assistant",
      },
    ],
  };
  return mockRes;
} */
