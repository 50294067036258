import utils from "../utils/utils";
import { jobs } from "./servermocks/jobs";

export const getAllJobCards = () => {
  const message = getJobMessage(Object.keys(jobs));
  message.response.followupQuestions = [];
  return message;
};

export const getJobMessage = (jobdomains, conversationId, promptId) => {
  conversationId = conversationId || utils.randomId();
  promptId = promptId || utils.randomId();
  let responseId = utils.randomId();
  return {
    conversation: {
      convoId: conversationId,
      created: "2023-05-23T10:40:10.284Z",
      updated: null,
      title: "Looking for Mr. Profile",
      userId: "2a94e40a-e4c7-4779-99o2-c5597396bbdd",
    },
    prompt: {
      promptId: promptId,
      convoId: conversationId,
      responseId: responseId,
      created: "2023-05-23T10:40:11.441Z",
      content:
        "Find remote jobs in the USA that are hiring for full-time Sr flutter developers with +5 years of working experience with Javascript",
      reponseType: "jobs",
    },
    response: {
      responseId: responseId,
      promptId: promptId,
      convoId: conversationId,
      created: "2023-05-23T10:40:12.999Z",
      responseType: "jobs",
      numberResults: 6,
      numberDisplayed: 3,
      actionsAvailable: true,
      preText: "Response for Find jobs for nodejs devs in Atlanta",
      postText: null,
      response: null,
      profiles: [],
      companies: [],
      followupQuestions: [
        {
          questionId: utils.randomId(),
          question:
            "Can you find me the recruiters hiring for those positions?",
          additionalQuestion:
            "Definitely! Select the job and I'll do my best to find the recruiters there.",
          action: "selector-single",
          preprompt: null,
          prompt:
            "Can you find me the recruiters from <employerName> (JobId: <id>)?",
        },
        {
          questionId: utils.randomId(),
          question: "Can you write me a cover letter for one of those jobs?",
          additionalQuestion:
            "Sure! Can you select the company and provide your linkedin profile? I can customize the letter based on your skills and experience?",
          action: "selector-single",
          preprompt: {
            display: "Linkedin URL - ",
            extractionRegex:
              "(https?:\\/\\/)?(www\\.)?linkedin\\.com\\/[^ \\n]*",
          },
          prompt:
            "Here is my linkedin <extraction>. Write a cover letter for the role from <employerName> (JobId: <id>)",
        },
        {
          questionId: utils.randomId(),
          question: "Can you give me other similar profiles?",
          action: "",
        },
      ],
      actions: [{ display: "View More", action: "view-more" }],
      jobs: jobdomains.map((domain) => jobs[domain]),
    },
  };
};

/* 

        {
          id: utils.randomId(),
          externalId: null,
          title: "React/NodeJs Developer - Remote (only on w2)",
          location: "Anywhere",
          timestamp: "2023-06-28T03:58:55.251Z",
          timeAgo: "3 days ago",
          contractType: "Full-time",
          sourceName: "via CareerBuilder",
          sourceUrl: "https://www.careerbuilder.com/job/J3M1PV5W03BZTCQM011",
          employerName: "Tech One IT",
          employerUrl: "www.techoneit.com",
          employerImageUrl:
            "https://play-lh.googleusercontent.com/S4SYraF727oJgsuEh8lEwV_RladmWJFML--52OQfjk71hV6S6n42tL-oXUXhlEJ3ng",
          jobDescription:
            "Staff Software Engineer – 100% Remote Opportunity Client Location: Glendale, AZ... Duration: 6 Months Only on W2 Interview process: Online coding exercise, one hour. Location: Remote Time Zone Preference? Arizona Are you willing to hire someone in California (pending additional approval)? NO Skills:React, Node, Typescript and AWS Only on W2 Duties:What team will this position be supporting: Online quoting engines and customer self-service portal. Position Length: 6 months prior to conversion Key technologies: React, Node, Typescript, AWS. One role could use Java experience as well. Nice to haves: insurance domain expertise: Insurance industry experience, Java.",
          qualifications: "React, Node, Typescript, AWS",
          compensation: "",
          salary: "",
        },
        {
          id: utils.randomId(),
          externalId: null,
          title: "NodeJS Developer- Remote",
          location: "Atlanta, GA",
          timestamp: "2023-06-28T03:58:55.251Z",
          timeAgo: "",
          contractType: "Contract (6 – 12+ Months)",
          sourceName: "via USNLX Virtual Jobs - National Labor Exchange",
          sourceUrl:
            "https://virtualjobs.usnlx.com/atlanta-ga/nodejs-developer-remote/7F00262732D241CBA52F9E51004D2258/job/",
          employerName: "Two95 International Inc.",
          employerUrl: "http://www.two95intl.com/",
          employerImageUrl:
            "https://www.freepnglogos.com/uploads/google-logo-png/google-logo-png-google-logos-vector-eps-cdr-svg-download-10.png",
          jobDescription:
            "• Experience working with Node.js and AWS in a micro-service and container based architecture\n• 3+ years of Node backend Development\n• Experience in Javascript\n• Must have experience with AWS Serverless, Lambda and DynamoDB – API Gateway is also very helpful\n• Experience building and integrating with API’s (RESTful or GraphQL)",
          qualifications: "No degree mentioned",
          compensation: "Rate - $Open (Best Possible)",
          salary: "",
        },
        {
          id: utils.randomId(),
          externalId: null,
          title: "Atlanta GA Node. JS Developer Hybrid RemoteOnsite ATL",
          location: " Atlanta, GA",
          timestamp: "2023-06-28T03:58:55.251Z",
          timeAgo: "2 days ago",
          contractType: "Full-time",
          sourceName: "via Jobrapido.com",
          sourceUrl: "https://us.jobrapido.com/jobpreview/2937081933",
          employerName: "Insight Global",
          employerUrl: "http://www.insightglobal.com/",
          employerImageUrl:
            "https://e7.pngegg.com/pngimages/581/486/png-clipart-pipedrive-vertical-logo-tech-companies.png",
          jobDescription:
            "Node. JS Developer Hybrid RemoteOnsite ATL-Insight Global A client is looking for a highly capable Node. js devel.",
          qualifications:
            "Bachelor's degree in computer science, information science, or similar.s",
          compensation: "",
          salary: "",
        },


        */
