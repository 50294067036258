import React from "react";
import styles from "styles/Message.module.css";

// Simple component to render a text message with code blocks
// We may use something like highlight.js later if we need to highlight code

function formatTextWithNewlines(text) {
  return text.split("\n").map((line, i) => (
    // Avoid adding an extra <br> for the last line
    <React.Fragment key={i}>
      {line}
      {i < text.split("\n").length - 1 && <br />}
    </React.Fragment>
  ));
}

export default function TextMessage({ content }) {
  // Temporarily wrap everything in code tags
  content = `<code>${content}</code>`;

  const parts = content.split(/(<code>|<\/code>)/g);
  let isCode = false;
  return (
    // TODO use real id
    <div className={styles.textMessage}>
      {parts.map((part, index) => {
        if (part === "<code>") {
          isCode = true;
          return null;
        }
        if (part === "</code>") {
          isCode = false;
          return null;
        }
        if (isCode) {
          return <code key={index}>{formatTextWithNewlines(part.trim())}</code>;
        } else {
          return <span key={index}>{formatTextWithNewlines(part)}</span>;
        }
      })}
    </div>
  );
}
