// Type different types of buttons
// Dashed
import styles from "styles/Button.module.css";

export default function Button({
  text,
  onClick,
  active,
  children,
  selected,
  type,
}) {
  var variant = "";
  if (type === "clear") variant = styles.clear;
  if (type === "dashed") variant = styles.dashed;

  return (
    <button
      onClick={onClick}
      className={`
      ${styles.button}
      ${selected ? styles.selected : ""}
      ${variant}`}
    >
      {children}
    </button>
  );
}
