import {
  getAllCompanyCards,
  getSimCompanySalesMessages,
  getSimJobsMessages,
} from "./mockCompanyMessage";
import { getAllJobCards, getJobMessage } from "./mockJobMessage";
import {
  getAllProfileCards,
  getProfileMessage,
  getSimEntrepreneurMessages,
  getSimInvestorMessages,
  getSimRecruitingMessages,
  getSimSalesMessages,
} from "./mockProfileMessage";

// conversation id, mock data pair
export const messagesData = {
  allcards: {
    success: true,
    results: [getAllJobCards(), getAllCompanyCards(), getAllProfileCards()],
    simResultsQueue: [],
  },

  live_one: {
    success: true,
    results: [],
  },

  live_two: {
    success: true,
    results: [],
  },
  dev: {
    success: true,
    results: [
      getJobMessage(["google", "jpmorgan", "sofi", "mobilecoin"]),
      getProfileMessage(["brianchesky", "juliahartz", "reedhastings"]),
    ],
    simResultsQueue: [],
  },
  icprecruiting: {
    success: true,
    results: [],
    simResultsQueue: getSimRecruitingMessages(), // simulated Results queue for demo
  },

  icpsales: {
    success: true,
    results: [],
    simResultsQueue: getSimSalesMessages(),
  },

  icpentrepreneurs: {
    success: true,
    results: [],
    simResultsQueue: getSimEntrepreneurMessages(),
  },

  icpinvestors: {
    success: true,
    results: [],
    simResultsQueue: getSimInvestorMessages(),
  },
  icpcompanysales: {
    success: true,
    results: [],
    simResultsQueue: getSimCompanySalesMessages(),
  },
  icpjobs: {
    success: true,
    results: [],
    simResultsQueue: getSimJobsMessages(),
  },

  /*
  random: {
    success: true,
    results: [],
  },*/
};

//export const getRandomMessageData = () => getJobMessage();
