import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useRef, useState } from "react";
import api from "api/api";
import { useChatStore, useDevStore } from "store/store";
import utils from "utils/utils";
import BotMessageItem from "./BotMessageItem";
import Terminal from "./Terminal";

import UserMessageItem from "./UserMessageItem";
import styles from "styles/ChatBox.module.css";
import ThinkingCursor from "components/common/ThinkingCursor";
import NormalTextInput from "components/chat/NormalTextInput";
import useAutoFocus from "hooks/useAutoFocus";
import useAutoScroll from "hooks/useAutoScroll";
import useSendPrompt from "hooks/useSendMessage";
import useSendMessage from "hooks/useSendMessage";
import useNewConversation from "hooks/useNewConversation";
import Spinner from "components/common/Spinner";
import ErrorPage from "pages/ErrorPage";
const HOTKEY = "1"; // Simulate typing a message and send it
const NEW_CONVO_ID = "new";

const ChatBox = ({ conversationId, isMostRecentConversation }) => {
  const inputPrompt = useChatStore((state) => state.inputPrompt);
  const setInputPrompt = useChatStore((state) => state.setInputPrompt);

  const [selectedCardIds, setSelectedCardIds] = useState([]);
  const legacyCards = useDevStore((state) => state.LegacyCards);

  const queryClient = useQueryClient();
  const {
    data: messages,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["conversations", conversationId],
    queryFn: api.fetchConversationData,
  });

  const inputRef = useAutoFocus(messages);
  const [upperRef, bottomRef] = useAutoScroll(conversationId, messages);

  // Direction is either 'up' or 'bottom'

  const simulateTyping = async (content) => {
    // simulate typing content character by character
    for (const character of content) {
      await utils.sleep(30);
      setInputPrompt((prev) => prev + character);
    }
  };

  const refetchConversations = !isMostRecentConversation;
  const sendMessageMutation = useSendMessage(
    conversationId,
    refetchConversations
  );

  const newConvoMutation = useNewConversation(NEW_CONVO_ID);

  const handleSend = async (message) => {
    //queryClient.invalidateQueries(["simConversations", conversationId]);
    if (message === "") return;
    if (conversationId === NEW_CONVO_ID) {
      newConvoMutation.mutate({
        inputPrompt: message,
      });
    } else {
      sendMessageMutation.mutate({
        inputPrompt: message,
      });
    }
    setInputPrompt("");
  };

  // TODO simplify this once we have a better idea of what the backend will return
  const handleFollowup = async (followupQuestion, responseMessage) => {
    sendMessageMutation.mutate({
      inputPrompt: followupQuestion.question,
      extra: {
        questionId: followupQuestion.questionId,
        action: followupQuestion.action,
        responseMessage: responseMessage,
        followupQuestion: followupQuestion,
      },
    });
    if (followupQuestion.preprompt) {
      setInputPrompt(followupQuestion.preprompt.display);
    }
  };

  const handleCardSelect = (cardId, item) => {
    // Todo llater we only need to pass item essentially cardId is already in item
    // but backend has different names for different card types jobId, companyId...
    // Fix that either in the backend or in a more ellegant way
    // handle card selection depending on action type we have selectror-single
    // or selector-multiple
    if (lastMessage.response.action === "selector-single") {
      setSelectedCardIds([cardId]);
    } else if (lastMessage.response.action === "selector-multiple") {
      if (selectedCardIds.includes(cardId)) {
        setSelectedCardIds(selectedCardIds.filter((id) => id !== cardId));
      } else {
        setSelectedCardIds([...selectedCardIds, cardId]);
      }
    }

    if (lastMessage.response.action === "selector-single") {
      // Add extraction to item so that we can use it in the prompt
      if (lastMessage.response.preprompt) {
        item.extraction = utils.extractSubstring(
          inputPrompt,
          lastMessage.response.preprompt.extractionRegex
        );
        if (item.extraction == null) {
          // TODO better message if regext cant be extracted
          utils.notify(
            `Please enter the correct format for ${lastMessage.response.preprompt.display}`
          );
          return;
        }
      }

      const prompt =
        lastMessage.response.prompt &&
        utils.getFormattedPrompt(lastMessage.response.prompt, item);

      sendMessageMutation.mutate({ inputPrompt: prompt });
      setInputPrompt("");
    }
  };

  // For simulation purposes, TODO remove this later

  const { data: frontSimMessage, simError } = useQuery({
    queryKey: ["simConversations", conversationId],
    refetchInterval: 500,
    queryFn: api.getFrontSimMessage,
  });

  if (error) return <ErrorPage error={error} />;

  // TODO determine how to load more results from backend

  //const messages = data.results.slice().reverse();
  const lastMessage =
    messages?.length > 0 ? messages[messages.length - 1] : null;

  return (
    <div className={styles.container}>
      <div className={`box ${styles.messageArea}`}>
        <div ref={upperRef} />
        {isLoading && <Spinner />}
        {error && (
          <ErrorPage error={error} text="Failed to load the conversation" />
        )}
        {messages &&
          messages.map((message, index) => {
            const actionType =
              lastMessage.response.actionableResponseId ===
              message.response.responseId
                ? lastMessage.response.action
                : null;
            return (
              <React.Fragment key={message.prompt.promptId}>
                <UserMessageItem prompt={message.prompt} />
                <BotMessageItem
                  initialCardsNum={
                    conversationId.startsWith("allcards") ? 100 : 3 // 100 for tsting purposes
                  }
                  legacy={legacyCards}
                  actionType={actionType}
                  selectedCardIds={selectedCardIds}
                  handleCardSelect={handleCardSelect}
                  sendMessageFun={handleSend}
                  prompt={message.prompt}
                  responseMessage={message.response}
                  responseType={message.prompt.responseType}
                  handleFollowup={handleFollowup}
                  showFollowup={index === messages.length - 1}
                  showActions={index === messages.length - 1}
                />
              </React.Fragment>
            );
          })}
        <div ref={bottomRef} style={{ paddingBottom: 100 }} />
      </div>

      {/*
        <Terminal
          ref={inputRef}
          value={inputPrompt}
          onKeyPress={handleKeyPress}
          onChange={onChange}
        />
      */}

      {
        <NormalTextInput
          ref={inputRef}
          value={inputPrompt}
          onSendClicked={() => handleSend(inputPrompt)}
        />
      }
    </div>
  );
};

ChatBox.whyDidYouRender = true;

export default ChatBox;
