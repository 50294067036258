import React, { useEffect } from "react";
import { FaPaperPlane } from "react-icons/fa";
import { useChatStore, useHistoryStore } from "store/store";
import styles from "styles/NormalTextInput.module.css";

const NormalTextInput = React.forwardRef(({ value, onSendClicked }, ref) => {
  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = "auto"; // reset the height
      ref.current.style.height = `${ref.current.scrollHeight}px`; // set it to scrollHeight
    }
  }, [value]);

  const upPressedTimes = useChatStore((state) => state.upPressedTimes);
  const inputPrompt = useChatStore((state) => state.inputPrompt);
  const setInputPrompt = useChatStore((state) => state.setInputPrompt);
  const queryHistory = useHistoryStore((state) => state.queryHistory);
  const addQuery = useHistoryStore((state) => state.addQuery);

  const setUpPressedTimes = useChatStore((state) => state.setUpPressedTimes);

  const handleKeyPress = async (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }

    switch (event.key) {
      case "ArrowUp":
        event.preventDefault();
        if (upPressedTimes < queryHistory.length) {
          setInputPrompt(queryHistory[upPressedTimes]);
          setUpPressedTimes(upPressedTimes + 1);
        }
        break;
      case "ArrowDown":
        event.preventDefault();
        if (upPressedTimes === 1) {
          setInputPrompt("");
          setUpPressedTimes(upPressedTimes - 1);
        }
        if (upPressedTimes > 1) {
          setInputPrompt(queryHistory[upPressedTimes - 2]);
          setUpPressedTimes(upPressedTimes - 1);
        }
        break;
      default:
        setUpPressedTimes(0);
    }

    /* if (event.key === HOTKEY && frontSimMessage?.prompt?.simulated) {
      // Simulate typing a message and send it
      simulateTyping(frontSimMessage.prompt.content);
        }*/
  };

  const onChange = (event) => {
    event.preventDefault();
    setInputPrompt(event.target.value);
  };

  const handleSend = () => {
    const trimmedPrompt = inputPrompt.trim();
    if (trimmedPrompt.length === 0) return;
    addQuery(trimmedPrompt);
    onSendClicked();
  };

  return (
    <div>
      <div className={` ${styles.inputBox}`}>
        <div id={styles.dollarSign}>$</div>
        <textarea
          contentEditable
          id={styles.messageInput}
          placeholder=""
          ref={ref}
          style={{
            whiteSpace: "pre-wrap",
          }}
          rows={1}
          onInput={() => {}}
          value={value}
          onKeyDown={handleKeyPress}
          onChange={onChange}
          suppressContentEditableWarning={true}
        ></textarea>
        <div
          id={styles.sendButton}
          onClick={handleSend}
          className={inputPrompt.trim().length === 0 && styles.inactive}
        >
          <FaPaperPlane size={20} />
        </div>
      </div>
    </div>
  );
});

export default NormalTextInput;
