// Component that displays shortened text and expands when more clicked
import React, { useState } from "react";

export function ExpandableText({ text, className, foldedLength }) {
  const [expanded, setExpanded] = useState(false);
  if (!text) return null;
  const displayText = expanded ? text : text.slice(0, foldedLength) + "...";
  return (
    <p className={className}>
      {displayText}
      {!expanded && (
        <span className="clickable" onClick={() => setExpanded(true)}>
          {" "}
          more
        </span>
      )}
    </p>
  );
}
