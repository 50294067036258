// handle api errors

import utils from "utils/utils";

export class ResponseError extends Error {
  constructor(response) {
    super(response.statusText);
    this.name = "ResponseError";
    this.response = response;
  }
}

// When data doesn't match the expected format
export class DataError extends Error {
  constructor(message) {
    super(message);
    this.name = "DataError";
  }
}

// Any api error that is not a response error
export class FetchError extends Error {
  constructor(message) {
    super(message);
    this.name = "FetchError";
  }
}

export const getHumanReadableError = (error, devMode) => {
  if (error instanceof ResponseError) {
    return "ResponseError: " + error.response.status;
  } else if (error instanceof FetchError) {
    return "Fetch Error: " + error.toString();
  } else if (error instanceof DataError) {
    return "Data Error: " + error.toString();
  } else {
    return "Other error: " + error.toString();
  }
};

export const handleError = (error) => {
  console.log(error);
  //utils.notify(error.toString());
  if (error instanceof ResponseError) {
    utils.notify("ResponseError: ", +error.response.status);
  } else if (error instanceof FetchError) {
    utils.notify("Fetch  Error: " + error.message);
  } else if (error instanceof DataError) {
    utils.notify("Data Error: " + error.toString());
  } else {
    utils.notify("Other error: " + error.toString());
  }
};
