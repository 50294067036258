import styles from "styles/ConfigSection.module.css";

export function ConfigTitle({ title, subtitle }) {
  return (
    <div className={styles.title}>
      <h1 className={styles.configTitle}> {title} </h1>
      <p className={styles.configSubtitle}> {subtitle} </p>
    </div>
  );
}

export function ConfigSection({ children, title }) {
  return (
    <div>
      <div className={styles.sectionTitle}>
        <p> {title} </p>
      </div>
      <div className={styles.sectionContent}>{children}</div>
    </div>
  );
}
