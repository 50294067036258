// Many functions are similar to useNewMessage.js
// TODO: see how different they end up and if we can factor out the common parts

import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api/api";
import { useDevStore } from "store/store";
import { useNavigate } from "react-router-dom";
import utils from "utils/utils";
import { FetchError, getHumanReadableError } from "api/error";

export default function useNewConversation(conversationId) {
  const queryClient = useQueryClient();
  const devMode = useDevStore((state) => state.devMode);
  const navigate = useNavigate();

  const newConvoMutation = useMutation({
    mutationFn: api.addNewConversation,
    onSuccess: (data) => {
      // data is the new conversation object
      queryClient.setQueryData(
        ["conversations", data.result.convoId],
        queryClient.getQueryData(["conversations", conversationId])
      );
      queryClient.invalidateQueries({ queryKey: ["conversations"] });
      navigate(`/chat/${data.result.convoId}`);
    },

    onMutate: async ({ inputPrompt }) => {
      const newMessage = {
        prompt: {
          promptId: utils.randomId(),
          content: inputPrompt,
          responseType: "loading",
        },
        converation: {
          convoId: conversationId,
        },
        response: {
          content: "loading",
        },
      };
      // Snapshot the previous value
      const previousMessages = queryClient.getQueryData([
        "conversations",
        conversationId,
      ]);
      queryClient.setQueryData(
        ["conversations", conversationId],
        (oldMessages) => [...oldMessages, newMessage]
      );
      return { previousMessages };
    },
    retry: (failureCount, error) => {
      //console.log("retrying", failureCount, error);
      if (failureCount === 0 && error instanceof FetchError) {
        console.log("Retrying because of Error", error);
        return true;
      } else {
        return false;
      }
    },
    retryDelay: 3000,
    onError: (err, { inputPrompt }, context) => {
      // TODO handle error
      console.log(err);
      const errorStr = getHumanReadableError(err, devMode);
      const newMessage = {
        prompt: {
          promptId: utils.randomId(),
          content: inputPrompt,
          responseType: "text",
        },
        converation: {
          convoId: conversationId,
        },
        response: {
          content: errorStr,
        },
      };
      // If there is an error fallback to the previous value + new
      // error message
      queryClient.setQueryData(
        ["conversations", conversationId],
        //context.previousMessages
        [...context.previousMessages, newMessage]
      );
    },
  });
  return newConvoMutation;
}
