// Common header for cards - left side is an image,
// right side is a vertical list of items

import CardImage from "components/cards/CardImage";
import styles from "styles/CardHeader.module.css";

export default function CardHeader({ image, children }) {
  return (
    <div className={styles.container}>
      <CardImage src={image} className={styles.headerImage} />
      <div className={styles.rightSide}>{children}</div>
    </div>
  );
}
