// Component to requst more results to be sent to email

import { useRef, useEffect, useState } from "react";
import lottie from "lottie-web";
import api from "api/api";
import animationData from "assets/lottie/success.json";

import arrow from "assets/svgarrow.svg";
import styles from "styles/AskMoreCard.module.css";
import Button from "components/common/Button";
import { useMutation } from "@tanstack/react-query";
import { MdOutlineEmail } from "react-icons/md";
import CheckboxField from "components/common/CheckboxField";
import utils from "utils/utils";
import TextWithIcon from "components/common/TextWithIcon";
import RadioField from "components/common/RadioField";

export default function AskMoreCard({ prompt }) {
  const element = useRef(null);

  const checkboxes = [
    { label: "Personal Emails", code: 1, promptString: "Get personal emails." },
    { label: "Work Emails", code: 2, promptString: "Get work emails." },
    { label: "Additional AI Analysis", code: 3, promptString: "" },
  ];

  const countOptions = [50, 100, 200];

  const [checkedItemCodes, setCheckedItemCodes] = useState([]);
  const [email, setEmail] = useState("");
  const [selectedCountOption, setSelectedCountOption] = useState(
    countOptions[0]
  );

  const [emailSent, setEmailSent] = useState(false);

  const lottieInstance = useRef();

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  useEffect(() => {
    if (element.current) {
      lottieInstance.current = lottie.loadAnimation({
        animationData,
        container: element.current,
        autoplay: true,
        loop: false,
      });
    }
    return () => {
      lottieInstance.current?.destroy();
    };
  }, [emailSent]);

  const askMoreMutation = useMutation({
    mutationFn: api.askMoreResults,
    onSuccess: (data) => {
      // Run lottie animation
      setEmailSent(true);
    },
  });

  const handleCheck = (itemCode) => {
    setCheckedItemCodes((prev) => {
      if (prev.includes(itemCode)) {
        return prev.filter((i) => i !== itemCode);
      } else {
        return [...prev, itemCode];
      }
    });
  };

  const handleSendClicked = () => {
    if (isValidEmail(email)) {
      // Buld an array with both label and code

      const checkedItems = checkboxes.filter((item) =>
        checkedItemCodes.includes(item.code)
      );

      const additionalPrompt = checkedItems
        .map((item) => item.promptString)
        .join(" ");

      askMoreMutation.mutate({
        //checkedItems: checkedItems,
        email: email,
        //prompt: `get me ${selectedCountOption} results for ${prompt.content}`,
        prompt: `get me ${selectedCountOption} results for the following prompt: ${prompt.content}. ${additionalPrompt}`,
      });
      /*utils.notify(
        "Please check both your primary inbox & spam inbox for an email from us"
      );*/
    } else {
      utils.notify("Please enter a valid email");
    }
  };

  return (
    <>
      <div className={styles.overlay}>
        {emailSent ? (
          <div className={styles.successContainer}>
            <div className={styles.lottie} ref={element}></div>
            <div className={styles.successText}>
              <p> Estimated 15 minutes </p>
              <p> Sending to {email} </p>
              <p className={styles.warningMessage}>
                Please check both your primary inbox & spam inbox for an email
                from us
              </p>
            </div>
          </div>
        ) : (
          <div className={styles.inputForm}>
            <p className={styles.header}>
              Get the next {selectedCountOption} results in your inbox
            </p>

            <div className={styles.textInput}>
              <input
                type="text"
                placeholder="Enter your email"
                value={email}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSendClicked();
                  }
                }}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 20,
                marginTop: 5,
                marginBottom: 10,
              }}
            >
              {countOptions.map((number, index) => (
                <div key={number}>
                  <RadioField
                    name="countOptions"
                    value={number}
                    checked={number === selectedCountOption}
                    onChange={(e) => {
                      setSelectedCountOption(parseInt(e.target.value));
                    }}
                  />
                </div>
              ))}
            </div>

            <p className={styles.includeText}> Include: </p>

            {checkboxes.map((item) => {
              return (
                <div key={item.code}>
                  <CheckboxField
                    label={item.label}
                    checked={checkedItemCodes.includes(item.code)}
                    onChange={() => handleCheck(item.code)}
                  />
                </div>
              );
            })}
            <div className={styles.sendButton}>
              <Button onClick={handleSendClicked}>
                <TextWithIcon
                  text="Send"
                  iconFontSize={17}
                  textFontSize={16}
                  textClassName={styles.sendButtonText}
                  iconLeft={false}
                  icon={<img src={arrow} alt="send" height={14} />}
                />
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
