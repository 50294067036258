import { SignIn } from "@clerk/clerk-react";
import Gradient from "components/common/Gradient";
import useClearLocalStorage from "hooks/useClearLocalStorage";
import styles from "styles/Pages.module.css";
export default function SingInPage() {
  useClearLocalStorage();
  return (
    <div className={styles.authContainer}>
      <SignIn />
    </div>
  );
}
