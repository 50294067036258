// State management with Zustand
// Keep the states in one file now, split it up if it gets too big

import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export const useDevStore = create((set) => ({
  devMode: false, // Development mode
  setDevMode: (flag) => set((state) => ({ devMode: flag })),
  LegacyCards: false, //
  setLegacyCards: (flag) => set((state) => ({ LegacyCards: flag })),
}));

const mediaQuery = window.matchMedia("(min-width: 992px)");

export const useUserStore = create((set) => ({
  isProfileModalOpen: false,
  setProfileModalOpen: (flag) => set((state) => ({ isProfileModalOpen: flag })),
  showSidebar: mediaQuery.matches,
  setShowSidebar: (flag) => set((state) => ({ showSidebar: flag })),
}));

export const useChatStore = create((set) => ({
  upPressedTimes: 0,
  setUpPressedTimes: (times) => set((state) => ({ upPressedTimes: times })),

  inputPrompt: "",
  setInputPrompt: (prompt) => set((state) => ({ inputPrompt: prompt })),
}));

const MAX_QUERY_HISTORY = 50;
export const useHistoryStore = create(
  persist(
    (set, get) => ({
      queryHistory: [],
      addQuery: (query) =>
        set({
          queryHistory: [query, ...get().queryHistory].splice(
            0,
            MAX_QUERY_HISTORY
          ),
        }),
    }),
    {
      name: "query-history",
    }
  )
);
