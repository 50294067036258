import React from "react";
import { FaClock, FaFileContract } from "react-icons/fa";

import { FaLocationPin, FaMoneyBillTrendUp } from "react-icons/fa6";
import { LinkWithIcon } from "../common/LinkWithIcon";
import TextWithIcon from "../common/TextWithIcon";
import CardSection from "./CardSection";
import ParagraphInfo from "components/cards/ParagraphInfo";
import CardHeader from "components/cards/CardHeader";
import styles from "styles/JobCard.module.css";

export default function JobCard({ job, bottomArea, selected }) {
  // if action type is not select, then we don't need to show some buttons
  // TODO use constants for action types
  // Todo customize card icons etc, once we know what we are sowing

  // funding message

  return (
    <React.Fragment>
      {/*<i className="bookmark fa-sharp fa-light fa-bookmark"></i>*/}
      <CardHeader image={job.employerImageUrl}>
        <h3> {job.title} </h3>
        <p> {job.employerName} </p>

        {job.jobURL && (
          <LinkWithIcon href={job.jobURL?.[0]} text={job.sourceName} />
        )}
      </CardHeader>

      <div className={styles.statusLine}>
        <TextWithIcon icon={<FaLocationPin />} text={job.location} />
        <TextWithIcon icon={<FaClock />} text={job.timeAgo}></TextWithIcon>
      </div>
      <div className={styles.statusLine}>
        <TextWithIcon icon={<FaFileContract />} text={job.contractType} />
        <TextWithIcon icon={<FaMoneyBillTrendUp />} text={job.salary} />
      </div>
      {
        <CardSection title="Company">
          {job.company || job.companyInfo}
        </CardSection>
      }
      {/*
      <CardSection title="Description">
        <BulletInfo items={job.jobInfo.slice(0, 1)} />
  </CardSection>*/}
      <CardSection title="Description">
        <ParagraphInfo items={job.description?.slice(0, 3)} />
      </CardSection>
      <CardSection title="Qualifications">
        <ParagraphInfo items={job.qualifications?.slice(0, 3)} />
      </CardSection>
      {bottomArea}
    </React.Fragment>
  );
}
