import { useEffect, useRef } from "react";
import styles from "styles/EditableTitle.module.css";
export default function EditableTitle({
  className,
  onKeyDown,
  type,
  value,
  onChange,
  readOnly,
  onRenameEnd,
}) {
  const inputRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        onRenameEnd(); // Call the function to handle end of renaming
      }
    }

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef, onRenameEnd]);

  return (
    <input
      ref={inputRef}
      className={`${styles.input} ${
        readOnly ? styles.readOnly : styles.editable
      }`}
      type={type}
      value={value}
      onChange={onChange}
      readOnly={readOnly}
      onKeyDown={onKeyDown}
    />
  );
}
