// Hook to auto focus on the input box after user interaction
import { useEffect, useRef } from "react";
import utils from "utils/utils";

export default function useAutoFocus(messages) {
  const inputRef = useRef(null);
  useEffect(() => {
    // Focus unless it's a mobile device as it brings up a keyboard
    if (!utils.isMobileDevice()) {
      inputRef.current?.focus();
    }
  }, [messages?.length]);

  /* One way to do it is watch the whole document for changes
  But we avoid it for now 
  /*useEffect(() => {
    // Always keep the focus on the input
    const handleMutation = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };
    const observer = new MutationObserver(handleMutation);
    observer.observe(document, {
      CharacterData: true,
      childList: true,
      subtree: true,
      attributes: true,
    });

    return () => {
      observer.disconnect();
    };
  }, []); */
  return inputRef;
}
