import { UserButton, SignOutButton, useUser } from "@clerk/clerk-react";
import CreditPage from "components/sidebar/CreditPage";
import { FaSignOutAlt } from "react-icons/fa";
import { useDevStore } from "store/store";
import styles from "styles/UserArea.module.css";
import { GiCreditsCurrency } from "react-icons/gi";
import ProgressBar from "components/sidebar/ProgressBar";

export default function UserArea() {
  const { isLoaded, isSignedIn, user } = useUser();
  const setDevMode = useDevStore((state) => state.setDevMode);
  if (user?.primaryEmailAddress.emailAddress.endsWith("@withsigma.com")) {
    setDevMode(true);
  }
  return (
    <div>
      <hr style={{ marginLeft: 0, marginRight: 0 }} />
      <div className={styles.container}>
        <ProgressBar value={75} max={100} />
        <div className={styles.profileControl}>
          <UserButton>
            <UserButton.UserProfilePage
              label="Credits"
              labelIcon={<GiCreditsCurrency size={19} />}
              url="custom"
            >
              <CreditPage />
            </UserButton.UserProfilePage>
          </UserButton>
          <span style={{ fontSize: 12, marginLeft: "7px" }}>
            {user?.primaryEmailAddress.emailAddress}
          </span>
        </div>
      </div>

      {/*
      <SignOutButton>
        <button>
          <FaSignOutAlt color="white" />
          <span>Sign out</span>
        </button>
      </SignOutButton>
          */}
    </div>
  );
}
