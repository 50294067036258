import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import ChatPage from "../pages/ChatPage";
import Protected from "../pages/Protected";
import SingInPage from "../pages/SignInPage";
import SignUpPage from "../pages/SignUpPage";
import Layout from "./Layout";
import { UserProfile } from "@clerk/clerk-react";
import UserProfilePage from "pages/UserProfilePage";

export default function SiteRouter() {
  return (
    <Layout>
      <Routes>
        <Route
          path="/sign-in/*"
          element={<SingInPage routing="path" path="/sign-in" />}
        />
        <Route
          path="/sign-up/*"
          element={<SignUpPage routing="path" path="/sign-up" />}
        />
        {
          <Route
            path="/user-profile"
            element={<UserProfilePage path="/user-profile" routing="path" />}
          />
        }

        <Route path="/chat">
          <Route
            path=":conversationId"
            element={
              <Protected>
                <ChatPage />
              </Protected>
            }
          />
        </Route>
        <Route
          path="/"
          element={
            <Protected>
              <ChatPage />
            </Protected>
          }
        />
      </Routes>
    </Layout>
  );
}
