import utils from "../utils/utils";
import { getJobMessage } from "./mockJobMessage";
import { getProfileMessage } from "./mockProfileMessage";
import companies from "./servermocks/companies.json";
import metaCompanies from "./servermocks/metaCompanies.json";
// Get mock data from the server for a demo

companies = utils.tweakMocks(companies, metaCompanies);

export const getAllCompanyCards = () => {
  const message = getCompanyMessage(Object.keys(companies));
  message.response.followupQuestions = [];
  return message;
};

export const getCompanyMessage = (domains, conversationId, promptId) => {
  conversationId = conversationId || utils.randomId();
  promptId = promptId || utils.randomId();
  let responseId = utils.randomId();
  return {
    conversation: {
      convoId: conversationId,
      created: "2023-05-23T10:40:10.284Z",
      updated: null,
      title: "Looking for Mr. Profile",
      userId: "2a94e40a-e4c7-4779-99o2-c5597396bbdd",
    },
    prompt: {
      promptId: promptId,
      convoId: conversationId,
      responseId: responseId,
      created: "2023-05-23T10:40:11.441Z",
      content: "Find jobs for nodejs devs in Atlanta",
      reponseType: "companies",
    },
    response: {
      responseId: responseId,
      promptId: promptId,
      convoId: conversationId,
      created: "2023-05-23T10:40:12.999Z",
      responseType: "companies",
      numberResults: 6,
      numberDisplayed: 3,
      actionsAvailable: true,
      preText: null,
      postText: null,
      response: null,
      profiles: [],
      companies: domains.map((domain) => companies[domain]),
      followupQuestions: [
        {
          questionId: utils.randomId(),
          question:
            "Can you find me the recruiters hiring for those positions?",
          additionalQuestion:
            "Definitely! Select the job and I'll do my best to find the recruiters there.",
          action: "selector-single",
          preprompt: null,
          prompt:
            "Can you find me the recruiters from <employerName> (JobId: <id>)?",
        },
        {
          questionId: utils.randomId(),
          question: "Can you write me a cover letter for one of those jobs?",
          additionalQuestion:
            "Sure! Can you select the company and provide your linkedin profile? I can customize the letter based on your skills and experience?",
          action: "selector-single",
          preprompt: {
            display: "Linkedin URL - ",
            extractionRegex:
              "(https?:\\/\\/)?(www\\.)?linkedin\\.com\\/[^ \\n]*",
          },
          prompt:
            "Here is my linkedin <extraction>. Write a cover letter for the role from <employerName> (JobId: <id>)",
        },
        {
          questionId: utils.randomId(),
          question: "Can you tell me if I would be a good fit for those roles?",
          additionalQuestion:
            "I'll do my best! Can you select the company and provide your linkedin profile?",
          action: "selector-single",
          preprompt: {
            display: "Linkedin URL - ",
            extractionRegex:
              "(https?:\\/\\/)?(www\\.)?linkedin\\.com\\/[^ \\n]*",
          },
          prompt:
            "Here is my linkedin <extraction>, can you see if I am a good fit for the role from <employerName> (JobId: <id>)",
        },
      ],
      actions: [{ display: "View More", action: "view-more" }],
    },
  };
};

export const getSimCompanySalesMessages = () => {
  const messages = [
    {
      prompt: {
        content:
          "Find Fintech companies (<500 employees), based in the U.S. that sell an investing product",
        simulated: true,
      },
      response: {
        responseType: "companies",
        companies: getCompanyMessage([
          "titan.com",
          "stash.com",
          "wealthfront.com",
          "betterment.com",
          "acorns.com",
          "webull.com",
        ]).response.companies,
        followupQuestions: [
          {
            question: "Find similar companies selling crypto trading products",
            action: "",
          },
          {
            question: "Find similar companies based in Europe",
            action: "",
          },
        ],
      },
    },
    {
      prompt: {
        content: "Tell me more about Acorns",
        simulated: true,
      },
      response: {
        responseType: "text",
        content:
          "Acorns is a fintech company that simplifies investing through their platform. They pioneered micro-investing by rounding up everyday purchases and investing spare change into diversified portfolios. They offer financial products like retirement planning, debit cards, and adviser services.",
        followupQuestions: [
          {
            question: "Find decision makers at Acorns",
            action: "",
          },
          {
            question: "Find companies similar to Acorns",
            action: "",
          },
        ],
      },
    },
    {
      prompt: {},
      response: {
        responseType: "profiles",
        profiles: getProfileMessage(["noahkerner", "adamtreister"]).response
          .profiles,
      },
    },
    {
      prompt: {},
      response: {
        responseType: "text",
        content: "Sure here you go - noah@acorns.com",
      },
    },
    {
      prompt: {
        content:
          "Write an email to present my CyberSecurity tool designed for FinTechs to Noah Kerner",
        simulated: true,
      },
      response: {
        responseType: "text",
        content: `<code>
        Dear Noah,

I'm reaching out to introduce [Product Name] an advanced cybersecurity tool. With cyber threats on the rise, we know how important it is to offer extra protection for Acorns' valuable user data.

[Product Name] seamlessly integrates into your existing systems and ensures your digital fortress remains impenetrable.

Can we do a quick 15-min demo next week?

Looking forward to connecting soon!

[Your Name]
VP CyberSecurity Solutions 
[Your Company Name]
[Contact Information]
          </code>
        `,
      },
    },
  ];

  return utils.fillIdFields(messages);
};

export const getSimJobsMessages = () => {
  const messages = [
    {
      prompt: {
        content:
          "Find tech companies in the U.S. that are hiring for Sr Mobile Devs with 5+ years of experience",
        simulated: true,
      },
      response: {
        responseType: "jobs",
        jobs: getJobMessage(["sofi", "mobilecoin", "google"]).response.jobs,
        followupQuestions: [
          {
            question: "Filter for similar positions that are 100% remote",
            action: "",
          },
          {
            question: "Filter for similar jobs from companies based in Europe",
            action: "",
          },
        ],
      },
    },
    {
      prompt: {},
      response: {
        responseId: "tornikejobs",
        responseType: "jobs",
        jobs: getJobMessage(["jpmorgan", "dropbox", "bitfinex"]).response.jobs,
        followupQuestions: [
          {
            question: "Find recruiters hiring for one of these roles",
            action: "",
          },
          {
            question:
              "Can you check if I am a good fit for one of these roles?",
            additionalQuestion:
              "Definitely! Provide your Linkedin, select the job and I'll do my best to analyze your profile for the selected role",
            action: "selector-single",
            preprompt: {
              display: "",
              extractionRegex:
                "(https?:\\/\\/)?(www\\.)?linkedin\\.com\\/[^ \\n]*",
            },
            prompt:
              "Here is my Linkedin profile: <extraction>, can you see if I am a good fit for the role from <employerName>",
          },
        ],
      },
    },
    {
      prompt: {},
      response: {
        responseType: "text",
        content:
          "According to your profile - you have strong programming fundamentals, a problem-solving mindset, and your extensive experience makes you a strong match for this position. I encourage you to apply!",
        followupQuestions: [
          {
            question: "Find recruiters hiring for this position",
            action: "",
          },
          {
            question:
              "Find me a peer working at JP Morgan, that can give me an intro for this role",
            action: "",
          },
        ],
      },
    },
    {
      prompt: {},
      response: {
        responseType: "profiles",
        profiles: getProfileMessage(["ankita-goyal"]).response.profiles,
        followupQuestions: [
          {
            question:
              "Write a cover letter to apply for the job at JPMorgan & Chase Co",
            action: "",
          },
          {
            question:
              "Find me a peer working at JP Morgan, that can give me an intro for this role",
            action: "",
          },
        ],
      },
    },
    {
      prompt: {},
      response: {
        responseType: "text",
        content: `<code>
        Dear Ms. Goyal,

        As an experienced Flutter developer, I am excited to apply for JP Morgan's senior mobile engineering role. My computer science degree, and previous FinTech experience would be helpful for your team.

        I've built many high rated apps that you find on my website (https://www.tornike.dev/). I have well rounded experience between writing API documents, building Web Apps and have worked on a few personal projects in Python & Node.js.

        Looking forward to hearing from you soon!

        Sincerely,

        [Your Name],
          </code>`,
      },
    },
  ];
  return utils.fillIdFields(messages);
};
