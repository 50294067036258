import { genFakeCompany } from "../mocks/mockCompanies";
import { getCompanyMessage } from "../mocks/mockCompanyMessage";
import { mockConversationsData } from "../mocks/mockConversations";
import { getJobMessage } from "../mocks/mockJobMessage";
//import { getRandomMessageData, messagesData } from "./mocks/mockMessages";
import { messagesData } from "../mocks/mockMessages";
import { getProfileMessage } from "../mocks/mockProfileMessage";
import utils from "../utils/utils";
import { FetchError, DataError, ResponseError, handleError } from "./error";

// const apiBaseUrl = "http://localhost:3002";
const apiBaseUrl = "https://api.networkgpt.co";
const useMockData = true;

function generateRandomId() {
  const randomId = Math.floor(Math.random() * 1000000) + 1;

  return randomId;
}

const apiHeaders = () => {
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
    //Authorization: `Bearer ${userSession.user.idToken}`,
  };
};

async function fetchResource(endpoint, method = "GET") {
  try {
    let response = await fetch(`${apiBaseUrl}/${endpoint}`, {
      method,
      headers: apiHeaders(),
    });

    if (!response.ok) {
      throw new ResponseError(response);
    }
    try {
      const data = await response.json();
      return data;
    } catch (err) {
      throw new DataError(err.message);
    }
  } catch (err) {
    throw new FetchError(err.message);
  }
}

async function postResource(endpoint, data, headers) {
  try {
    let response = await fetch(`${apiBaseUrl}/${endpoint}`, {
      method: "POST",
      headers: { ...apiHeaders(), ...headers },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new ResponseError(response);
    }
    try {
      const resData = await response.json();

      return resData;
    } catch (err) {
      throw new DataError(err.message);
    }
  } catch (err) {
    throw new FetchError(err.message);
  }
}

async function getFrontSimMessage({ queryKey }) {
  // returns the front simulated message from queue
  const [, conversationId] = queryKey;
  if (!messagesData.hasOwnProperty(conversationId)) return null;
  const simMessages = messagesData[conversationId].simResultsQueue;
  if (!simMessages || simMessages.length === 0) return null;
  else return simMessages[0];
}

async function fetchConversationData({ queryKey }) {
  const [, conversationId] = queryKey;
  if (conversationId === "new")
    // New conversation return empty data
    return [];

  const data = await fetchResource(`conversations/${conversationId}`);
  return data.result.slice().reverse();

  if (useMockData) {
    //await delay(2);
    //const data = getMockConversationData(conversationId);
    if (messagesData.hasOwnProperty(conversationId)) {
      const data = messagesData[conversationId];
      return data.results.slice().reverse();
    } else {
      utils.notify(`Conversation ${conversationId} not found in mock data.`);
      return;
    }
  }

  /*
  const data = await fetchResource(`conversations/${conversationId}?page=1`);
  const key = "results";
  if (data.hasOwnProperty(key)) {
    // TODO: remove this mock code once Companies is coming from the backend
    data.totalCount = data.totalCount + 1;
    const mockID = 100;
    const companyResult = await getMockCompanyResult(conversationId, mockID);
    data.results.push(companyResult);
    return data;
  } else {
    console.error(`${key} not found in returned data.`);
  }*/
}

async function fetchConversations() {
  // TODO validate result
  const data = await fetchResource("conversations");
  return data.result;

  if (useMockData) {
    await utils.sleep();
    return mockConversationsData.results;
  }
}

async function askMoreResults({ email, prompt }) {
  postResource("prompt/csv", {
    prompt: prompt,
    email: email,
    //checkedItems: checkedItems,
  });
  return true;
}

async function submitPrompt(payload) {
  const data = await postResource("analyze-prompt", payload);
  return data;
  await utils.sleep(10000);
  return {
    result: {
      response: {
        content: "Oh thats nice to hear!",
        responseType: "text",
      },
    },
  };
}

/* =========Mock Data ======================= */

async function loadMoreCompanies() {
  // TODO make it load from server once we know the api
  const newCompanies = [];
  for (let i = 0; i < 3; i++) {
    newCompanies.push(genFakeCompany());
  }
  return newCompanies;
}

// TODO move to mock data file
async function getMockCompanyResult(convoId, useIdNumber) {
  const avvo = await fetchResource("companies/avvo.com");
  const amazon = await fetchResource("companies/amazon.com");
  const gucci = await fetchResource("companies/gucci.com");
  avvo.id = generateRandomId();
  amazon.id = generateRandomId();
  gucci.id = generateRandomId();

  const mockDataResults = {
    id: useIdNumber,
    created: "2023-06-09T15:28:15.409Z",
    updated: "2023-06-09T15:28:23.364Z",
    content: "I would like to find companies in Seattle",
    reponseType: "companies",
    conversationId: convoId,
    responseId: useIdNumber,
    response: {
      id: useIdNumber,
      created: "2023-06-09T15:28:17.119Z",
      updated: "2023-06-09T15:28:17.119Z",
      responseType: "companies",
      numberResults: 3,
      numberDisplayed: 3,
      actionsAvailable: true,
      preText: "Response for I would like to find companies in Seattle",
      postText: null,
      response: null,
      followUpQuestions: [
        "Would you like to find similar companies in the U.S.?",
        "Find sales leaders in the above companies?",
        "Find the email address of the CEO in one of the above companies?",
      ],
      companies: [avvo, amazon, gucci],
    },
  };

  return mockDataResults;
}

function getMockConversationResponse({ conversationId, inputPrompt, extra }) {
  if (conversationId.startsWith("icp") && !extra?.action) {
    let frontMessage = messagesData[conversationId]["simResultsQueue"].shift();
    if (frontMessage === undefined)
      return {
        content:
          "End of simulation reached, can't take any more messags, sorry.",
      };
    return frontMessage.response;
  }
  if (extra?.action) {
    // action required after this response
    // TODO this may come from the backend and not be extracted
    // from followupQuestion, so we need to handle
    // once we have the api spec ready
    return {
      responseType: "action",
      action: extra.action,
      actionableResponseId: extra.responseMessage.responseId,
      content: extra.followupQuestion.additionalQuestion,
      prompt: extra.followupQuestion.prompt,
      preprompt: extra.followupQuestion.preprompt,
      //preprompt: extra.followupQuestion.preprompt,
    };
  }

  if (conversationId === "random") {
    // Return random conversation type response
    const randomTextResponse = {
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
      responseType: "text",
    };
    const randomResponses = {
      text: randomTextResponse,
      job: getJobMessage(conversationId).response,
      profile: getProfileMessage(conversationId).response,
      company: getCompanyMessage(conversationId).response,
    };

    for (const [key, value] of Object.entries(randomResponses)) {
      if (inputPrompt.includes(key)) {
        return value;
      }
    }
    const keys = Object.keys(randomResponses);

    let randomIndex = Math.floor(Math.random() * keys.length);
    return randomResponses[keys[randomIndex]];
  }

  // Fallback response
  return {
    content: "Oh thats nice to hear!",
    responseType: "text",
  };
}

async function postNewMessage({
  conversationId,
  inputPrompt,
  extra,
  getToken,
}) {
  // TODO this is a hack to get the front sim message
  /*const data = await submitPrompt({
    prompt: inputPrompt,
  }); */
  const data = await postResource(
    `conversations/${conversationId}/prompt`,
    {
      prompt: inputPrompt,
    },
    { Authorization: `Bearer ${await getToken()}` }
  );

  if (!data?.result.response) {
    throw new DataError("No response in data");
  }
  return data.result;

  const newMessage = {
    prompt: {
      promptId: utils.randomId(),
      created: utils.randomDate(),
      content: inputPrompt,
    },
    conversation: {
      convoId: conversationId,
    },
    response: {
      ...data.result.response,
      convoId: conversationId,
      promptId: utils.randomId(),
      responseId: utils.randomId(),
    },
  };
  messagesData[conversationId].result.unshift(newMessage);
  return newMessage;

  if (useMockData) {
    await utils.sleep(1000);
    const newMessage = {
      prompt: {
        promptId: utils.randomId(),
        created: utils.randomDate(),
        content: inputPrompt,
      },
      conversation: {
        convoId: conversationId,
      },
      response: {
        ...getMockConversationResponse({ conversationId, inputPrompt, extra }),
        convoId: conversationId,
        promptId: utils.randomId(),
        responseId: utils.randomId(),
      },
    };
    messagesData[conversationId].results.unshift(newMessage);
    return newMessage;
  }

  // Note we will probably get title from the backend
  // so no need to generate it here
  throw new Error("Not implemented");
}

export async function addNewConversation({ inputPrompt }) {
  const data = await postResource("conversations/prompt", {
    prompt: inputPrompt,
  });
  return data;

  if (useMockData) {
    const newConvo = {
      id: utils.randomId(),
      crated: utils.randomDate(),
      updated: utils.randomDate(),
      title: "New Conversation",
    };
    mockConversationsData.results.unshift(newConvo);
    messagesData[newConvo.id] = {
      success: true,
      results: [],
    };
    return newConvo;
  }

  // Note we will probably get title from the backend
  // so no need to generate it here
  throw new Error("Not implemented");
}

async function fetchCreditTransactions() {
  const data = await fetchResource("account/transactions");
  return data.transactions;
}

async function fetchCreditBalance() {
  const data = await fetchResource("account/balance");
  return data.balance;
}

async function renameConversation({ conversationId, newTitle }) {
  // TODO not yet implemented in the backend
  return { success: true };
  const data = await postResource(`conversations/${conversationId}/rename`, {
    title: newTitle,
  });
  return data;
}

const api = {
  fetchConversationData,
  fetchConversations,
  submitPrompt,
  loadMoreCompanies,
  addNewConversation,
  postNewMessage,
  getFrontSimMessage,
  askMoreResults,
  fetchCreditTransactions,
  fetchCreditBalance,
  renameConversation,
};
export default api;
