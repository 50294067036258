import React from "react";

// use aboslulte imports
import { useUserStore } from "store/store";
import ProfileModal from "components/common/ProfileModal";

export default function Layout({ children }) {
  const isProfileModalOpen = useUserStore((state) => state.isProfileModalOpen);
  const setIsProfileModalOpen = useUserStore(
    (state) => state.setProfileModalOpen
  );

  return (
    <React.Fragment>
      {children}
      <ProfileModal
        open={isProfileModalOpen}
        onClose={() => setIsProfileModalOpen(false)}
      />
    </React.Fragment>
  );
}
